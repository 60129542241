import { useMemo } from 'react'

import { apiClient } from 'api/apiClient/apiClient'
import { useQuery } from 'react-query'
import uiConfigStore from 'store/uiConfig/uiConfig'
import zoomStore from 'store/zoom/zoom'
import bugsnag from 'utils/bugsnag/bugsnag'
import Datetime from 'utils/datetime/datetime'
import { fetchDatasetsFromDbS } from 'utils/dbs/dbs'
import getQueue from 'utils/queue/queue'
import { snapshot, useSnapshot } from 'valtio'

import {
  calcDataset,
  getCalcDatasetInstructionOrder,
  fillMissingHours,
  mergeDatasetsWithSameReturnId,
  getEarliestStartTime,
  getLatestStartTime,
  fillPeriodWithFirstIndexValue,
  setNanIfZero,
  setSmallValuesToZero,
  addOneZeroValueToEndOfDataset,
  applyZoomToDatasets,
  limitDataset,
  getReturnIdsFromDatasetInstruction,
} from 'helpers/dataset.helper/dataset.helper'
import { clone, removeFalsyValues } from 'helpers/global.helper/global.helper'
import { queryClient } from 'helpers/queryClient'
import { generallyApplyAliases } from 'helpers/uiConfig.helper/uiConfig.helper'

// Try new dataset API endpoint with URL param datasetApiLegacyMode=0
const url = new URL(window.location.href)
export const DEFAULT_LEGACY_MODE = url.searchParams.get('datasetApiLegacyMode') !== '0'

export function datasetResToDatasets(datasetRes: DatasetResponse, datasetInstruction: DatasetInstruction): Dataset[] {
  // Dummy dataset instruction if none is provided that will apply no filters
  if (!datasetInstruction) {
    datasetInstruction = {
      filter: {},
    }
  }

  const times = datasetRes.data.map((d) => Datetime.toISOString(d.time))
  const returnIdToValues = datasetRes.data.reduce((acc, d) => {
    Object.entries(d)
      .filter(([k]) => k !== `time`)
      .forEach(([returnId, value]) => {
        if (!acc[returnId]) {
          acc[returnId] = []
        }

        acc[returnId].push(value as number | null)
      })

    return acc
  }, {} as Record<string, (number | null)[]>)


  if (datasetInstruction.filter?.limit_max !== undefined || datasetInstruction.filter?.limit_min !== undefined) {
    Object.entries(returnIdToValues).forEach(([returnId, values]) => {
      returnIdToValues[returnId] = limitDataset({ values }, datasetInstruction.filter).values
    })
  }
  if (datasetInstruction.filter?.zero_is_nan) {
    Object.entries(returnIdToValues).forEach(([returnId, values]) => {
      returnIdToValues[returnId] = setNanIfZero({ values }, datasetInstruction.filter).values
    })
  }

  if (datasetInstruction.filter?.mark_hours_ahead) {
    uiConfigStore.markHoursAhead = true
  } else {
    uiConfigStore.markHoursAhead = false
  }

  if (datasetInstruction.filter?.add_one_zero_value_to_end_of_dataset) {
    Object.entries(returnIdToValues).forEach(([returnId, values]) => {
      returnIdToValues[returnId] = addOneZeroValueToEndOfDataset({ values }).values
    })
  }

  if (datasetInstruction.filter?.show_as_negative) {
    Object.entries(returnIdToValues).forEach(([returnId, values]) => {
      returnIdToValues[returnId] = values.map((v) => (v !== null ? (v = -Math.abs(v)) : v))
    })
  }

  const datasets: Dataset[] = Object.entries(returnIdToValues).map(([return_id, values]) => {
    return {
      return_id,
      times: times.slice(0, values.length),
      values,
      status: datasetRes.status,
      error_msg: datasetRes.error_msg,
    }
  })

  datasets.forEach((dataset, index) => {
    if (datasetInstruction.filter.fill_period_with_first_index_value) {
      datasets[index] = fillPeriodWithFirstIndexValue(datasetInstruction, dataset)
    }
  })

  return datasets
}

export async function getDatasets(
  id: number,
  version: number,
  _datasetInstructions: DatasetInstruction[],
  datasetStartTime?: ISODateTime,
  datasetEndTime?: ISODateTime,
  options: {
    useQueue?: boolean,
    fillMissingHourTimestamps?: boolean,
    legacyMode?: boolean,
    overrideAlias?: UiConfigAliases
    onlyReturnIds?: string[],
    returnIdRegexFilter?: string,
  } = {
    useQueue: true,
    fillMissingHourTimestamps: true,
    legacyMode: true,
    overrideAlias: {},
    onlyReturnIds: [],
    returnIdRegexFilter: '.*',
  }
): Promise<Dataset[]> {
  const legacyMode = options?.legacyMode === undefined ? DEFAULT_LEGACY_MODE : options?.legacyMode

  if (!legacyMode && !id) {
    return []
  }

  if (!options?.overrideAlias) {
    options.overrideAlias = {}
  }

  if (legacyMode && !_datasetInstructions?.length) {
    return []
  } else if (!legacyMode && !_datasetInstructions?.length) {
    // Fallback to get dataset instruction from store
    const uiConfigSnap = snapshot(uiConfigStore)
    const uid = uiConfigSnap.idToUiConfig[id]?.uid
    const uiConfig = uiConfigSnap.getParsedUiConfig(uid, options.overrideAlias)
    _datasetInstructions = uiConfig?.dataset_instructions ?? []

    if (!_datasetInstructions?.length) {
      return []
    }
  }

  let datasetInstructions = clone(_datasetInstructions)

  if (datasetStartTime) {
    datasetInstructions = datasetInstructions.map((instruction) => ({
      ...instruction,
      filter: {
        ...instruction.filter,
        offset_start_time: 0,
        start_time: Datetime.toISOString(datasetStartTime),
      },
    }))

    options.overrideAlias.start_time = Datetime.toISOString(datasetStartTime)
    options.overrideAlias.offset_start_time = 0
  }

  if (datasetEndTime) {
    datasetInstructions = datasetInstructions.map((instruction) => ({
      ...instruction,
      filter: {
        ...instruction.filter,
        offset_end_time: 0,
        end_time: Datetime.toISOString(datasetEndTime),
      },
    }))

    options.overrideAlias.end_time = Datetime.toISOString(datasetEndTime)
    options.overrideAlias.offset_end_time = 0
  }

  let datasets: Dataset[] = []
  let datasetRes!: DatasetResponse

  // [2024-07-10] Mattias: Previous migration attempt to make dataset queries in the Django backend proved challenging. The new strategy is to use DbS with user specific tokens, see: https://app-eu.wrike.com/open.htm?id=1377383956 for more information.
  datasets = await fetchDatasetsFromDbS(
    datasetInstructions
  )

  // [2024-07-10] Mattias: This non-legacy code is now also legacy and will be removed when data fetching is migrated to DbS.
  if (!legacyMode) {
    const queue = getQueue()
    const onlyReturnIds = options.onlyReturnIds || []
    const returnIdRegexFilter = options.returnIdRegexFilter ?? '.*'
    const uiConfigSnap = snapshot(uiConfigStore)
    const layers = uiConfigSnap.getAliasAndReturnIdPrefixLayers(id, version, options.overrideAlias)

    await Promise.all(layers.map(async (layer) => {
      return queue(async () => {
        try {
          let only_return_ids = onlyReturnIds?.length ? onlyReturnIds : undefined

          // Remove return id prefix from only_return_ids in fetch
          if (only_return_ids && layer.returnIdPrefix) {

            only_return_ids = only_return_ids
              .map((id) => {
                if (!id.startsWith(layer.returnIdPrefix)) {
                  return id
                }

                return id.replace(layer.returnIdPrefix, '')
              })
              .filter((id) => id)
          }

          const datasetResStep = await apiClient<DatasetResponse>(
            `ui_config/${id}/${version}/datasets`,
            {
              method: 'POST',
              data: removeFalsyValues({
                ...layer.alias,
                only_return_ids,
                return_id_regex_pattern: returnIdRegexFilter,
              }),
            }
          )

          if (layer.returnIdPrefix) {
            datasetResStep.data = datasetResStep.data.map((d) => {
              const prefixed = { time: d.time }
              Object.entries(d).forEach(([k, v]) => {
                prefixed[`${layer.returnIdPrefix}${k}`] = v
              })

              return prefixed
            })
          }

          if (datasetInstructions.length) {
            // Apply individual dataset instruction filter to each dataset
            datasetInstructions.forEach((datasetInstruction) => {
              const returnIdsInDatasetInstruction = new Set(getReturnIdsFromDatasetInstruction(datasetInstruction))
              const datasetResStepFiltered: DatasetResponse = {
                ...datasetResStep,
                data: datasetResStep.data.map(d => {
                  const filtered = { time: d.time }
                  Object.entries(d).forEach(([k, v]) => {
                    if (returnIdsInDatasetInstruction.has(k)) {
                      filtered[k] = v
                    }
                  })

                  return filtered
                }),
              }
              const datasetsStep = datasetResToDatasets(datasetResStepFiltered, datasetInstruction)
              datasets.push(...datasetsStep)
            })
          } else {
            const datasetsStep = datasetResToDatasets(datasetResStep, datasetInstructions[0])
            datasets.push(...datasetsStep)
          }

        } catch (error: any) {
          bugsnag.notify(error as Error)
          const errorDataset: Dataset = {
            return_id: datasetInstructions[0].return_id,
            values: [],
            times: [],
            status: `error`,
            error_msg: error?.message ?? ``,
          }
          datasets.push(errorDataset)
        }
      })
    }))
  }

  // [2024-02-13] Mattias: Remove this code when the backend can handle all dataset instructions
  if (legacyMode) {
    const queue = getQueue()
    const legacyFetchedDatasets = (await Promise.all(
      datasetInstructions
        .filter(({ type }) => type !== `calc` && type !== 'dbs')
        .map(async (datasetInstruction) => {
          const errorDataset: Dataset = {
            return_id: datasetInstruction.return_id,
            values: [],
            times: [],
          }

          try {
            const backendDatasetInstruction = clone(datasetInstruction)
            if (backendDatasetInstruction.filter?.aggregate_in_frontend) {
              delete backendDatasetInstruction.filter.aggregate
            }

            if (backendDatasetInstruction.filter) {
              if (!backendDatasetInstruction.filter.start_time) {
                delete backendDatasetInstruction.filter.start_time
              }
              if (!backendDatasetInstruction.filter.end_time) {
                delete backendDatasetInstruction.filter.end_time
              }
            }
            if (options?.useQueue !== false) {
              return queue(async () => {
                try {
                  datasetRes = await apiClient<DatasetResponse>(
                    `dataset/${datasetInstruction.type}`,
                    {
                      method: `POST`,
                      data: backendDatasetInstruction,
                    }
                  )
                  return datasetResToDatasets(datasetRes, datasetInstruction)
                } catch (error: any) {
                  bugsnag.notify(error as Error)
                  errorDataset.status = `error`
                  errorDataset.error_msg = error?.message ?? ``
                  return [errorDataset]
                }
              })
            } else {
              datasetRes = await apiClient<DatasetResponse>(
                `dataset/${datasetInstruction.type}`,
                {
                  method: `POST`,
                  data: backendDatasetInstruction,
                }
              )
              return datasetResToDatasets(datasetRes, datasetInstruction)
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            bugsnag.notify(error as Error)
            errorDataset.status = `error`
            errorDataset.error_msg = error?.message ?? ``
          }

          return [errorDataset]
        }))).flat()

    datasets.push(...legacyFetchedDatasets)
  }

  // Merge datasets with same return_id
  datasets = mergeDatasetsWithSameReturnId(datasets)

  // fill missing hours
  if (options?.fillMissingHourTimestamps) {
    const earliestStartTime = getEarliestStartTime(datasetInstructions)
    const latestEndTime = getLatestStartTime(datasetInstructions)

    if (Datetime.isBefore(earliestStartTime, latestEndTime)) {
      datasets = fillMissingHours(datasets, earliestStartTime, latestEndTime)
    } else {
      bugsnag.notify(new Error(`Earliest start time (${earliestStartTime}) is after latest end time (${latestEndTime}) for ui config ${id} version ${version}`))
    }
  }

  return datasets
}

export function convertDatasetInstructionsToQueryKey(datasetInstrucitons: DatasetInstruction[]): DatasetInstruction[] {
  // Remove irrelevant data from dataset instruction that the server does not need. This will cache similar queries even if they are not identical.
  let di = clone(datasetInstrucitons)
  di = di.map(d => {
    if (d?.filter?.aggregate_in_frontend) {
      d.filter.aggregate = ''
    }

    return d
  })

  return di
}

export const DATASET_FOR_UI_CONFIG = 'datasetForUiConfig'
export const DATASET_FOR_UI_CONFIG_REFRESH_ON_OPTIMIZE = 'datasetForUiConfigRefreshOnOptimize'
export const DATASET_FOR_UI_CONFIG_REFRESH_ON_REAL_TIME_OPTIMIZE = 'datasetForUiConfigRefreshOnRealTimeOptimize'
export const DATASET_FOR_UI_CONFIG_REFRESH_FOLLOW_UP = 'datasetForUiConfigRefreshFollowUp'
export const DATASET_FOR_UI_CONFIG_REFRESH_ON_FUEL_PLAN = 'datasetForUiConfigRefreshOnFuelPlan'
export function useDatasets(
  id: number,
  version: number,
  _datasetInstructions: DatasetInstruction[],
  datasetStartTime?: ISODateTime,
  datasetEndTime?: ISODateTime,
  options: {
    ignoreZoom?: boolean,
    uid?: number,
    fillMissingHourTimestamps?: boolean,
    useQueue?: boolean,
    refreshOnOptimize?: boolean,
    datasetRefreshToken?: string,
    overrideAlias?: UiConfigAliases,
    legacyMode?: boolean,
    onlyReturnIds?: string[],
    returnIdRegexFilter?: string,
  } = {
    ignoreZoom: false,
    fillMissingHourTimestamps: true,
    datasetRefreshToken: DATASET_FOR_UI_CONFIG,
    overrideAlias: {},
    legacyMode: DEFAULT_LEGACY_MODE,
    onlyReturnIds: [],
    returnIdRegexFilter: '.*',
  }
) {
  /*
  1. Fetch & cache API-response for datasets for entire period selected.
  2. Apply zoom-state for path route.
  3. Calc datasets after zoom-state.
*/

  const queryRefreshKey = options?.datasetRefreshToken || DATASET_FOR_UI_CONFIG
  const zoomSnap = useSnapshot(zoomStore)
  const zoomStartTime = zoomSnap.startTime
  const zoomEndTime = zoomSnap.endTime
  const legacyMode = options?.legacyMode === undefined ? DEFAULT_LEGACY_MODE : options?.legacyMode

  // Cached API-call
  const datasetResponse = useQuery([queryRefreshKey, convertDatasetInstructionsToQueryKey(_datasetInstructions), datasetStartTime, datasetEndTime, options], async () => {
    const fillMissingHourTimestamps = options?.fillMissingHourTimestamps !== false
    const useQueue = options?.useQueue !== false

    return getDatasets(
      id,
      version,
      _datasetInstructions,
      datasetStartTime,
      datasetEndTime,
      {
        fillMissingHourTimestamps,
        useQueue,
        legacyMode,
        overrideAlias: options.overrideAlias,
        onlyReturnIds: options.onlyReturnIds,
        returnIdRegexFilter: options.returnIdRegexFilter,
      })
  })

  //only calc datasets when there is data or if zoomstate has changed
  const calculatedDatasets = useMemo(() => {
    let datasets = datasetResponse.data ?? []
    if (datasetResponse.status !== 'success') {
      return []
    }

    datasets = options.ignoreZoom
      ? clone(datasets || [])
      : clone(applyZoomToDatasets(datasets || [], zoomStartTime, zoomEndTime))

    // Calculation datasets (based on fetched datasets)
    return calculateCalcDatasets(_datasetInstructions, datasets)

  }, [_datasetInstructions, datasetResponse.data, datasetResponse.status, options.ignoreZoom, zoomEndTime, zoomStartTime])

  // Update datasetResponse with new datasets (calc, zoom and aggregate)
  if (calculatedDatasets.length) {
    datasetResponse.data = calculatedDatasets
  }
  return datasetResponse
}

export function calculateCalcDatasets(datasetInstructions: DatasetInstruction[], datasets: Dataset[]): Dataset[] {
  const calcDatasetInstructions = getCalcDatasetInstructionOrder(datasetInstructions, datasets)

  calcDatasetInstructions.forEach((datasetInstruction) => {

    let dataset = calcDataset(clone(datasets), datasetInstruction)

    if (datasetInstruction.filter.fill_period_with_first_index_value) {
      dataset = fillPeriodWithFirstIndexValue(datasetInstruction, dataset)
    }

    if (datasetInstruction.filter?.zero_is_nan) {
      const zerosAreNan = dataset.values.map((v) => (v === 0 ? null : v))
      dataset.values = zerosAreNan
    }

    datasets.push(dataset)
  })

  datasets = setSmallValuesToZero(datasets, 0.0001)

  return datasets
}

export function reFetchAllDatasets() {
  queryClient.invalidateQueries([DATASET_FOR_UI_CONFIG_REFRESH_ON_OPTIMIZE])
}

export function reFetchAllDatasetsOnFuelPlan() {
  queryClient.invalidateQueries([DATASET_FOR_UI_CONFIG_REFRESH_ON_FUEL_PLAN])
}

type OptResultSourcesResponse = {
  opt_job_type_id: number
  opt_model_id: number
  sources: string[]
}
export const DATASET_OPTRESULT_SOURCES = `datasetOptResultSources`
export function useDatasetOptResultSources(datasetInstruction: DatasetInstruction) {
  const contract = datasetInstruction.contract as DatasetContractOptResults
  const opt_model_id = generallyApplyAliases(contract.opt_model_id)
  const opt_job_type_id = generallyApplyAliases(contract.opt_job_type_id)
  const system_id = generallyApplyAliases(contract.system_id)
  const subtype = contract.subtype ?? ``

  return useQuery(
    [DATASET_OPTRESULT_SOURCES, datasetInstruction.type, opt_model_id, opt_job_type_id, subtype, system_id],
    async () => {
      let params = {}
      if (datasetInstruction.type !== `optresults`) {
        return []
      }

      if (!opt_job_type_id) {
        return []
      }

      if (!system_id && !opt_model_id) {
        return []
      }

      if (opt_model_id) {
        params = {
          opt_model_id,
          opt_job_type_id,
          subtype,
        }
      }

      if (system_id && !opt_model_id) {
        params = {
          system_id,
          opt_job_type_id,
          subtype,
        }
      }

      const { sources } = await apiClient<OptResultSourcesResponse>(`dataset/optresult/sources`, {
        params: params,
      })

      return sources
    }
  )
}

export const DATASET_INSTRUCTION = `datasetInstruction`
export function useDatasetInstructions(systemId: number, datasetTypes: string[], measDataForDisplay = false) {
  return useQuery([DATASET_INSTRUCTION, systemId, datasetTypes], async () => {
    if (datasetTypes.length === 0) {
      return []
    }
    const typesQuery = datasetTypes.map((type) => `${type}`).join(`&type=`)

    const data = apiClient<DatasetInstruction[]>('dataset/dataset_instructions', {
      method:'GET',
      params: {
        system_id: systemId,
        meas_data_for_display: measDataForDisplay,
        type: typesQuery,
      },
    })

    if (!data) {
      return []
    }
    return data
  })
}