import i18n from 'i18next'
import moment, { Moment } from 'moment'
import Datetime from 'utils/datetime/datetime'

export function formatPossibleNullTimestamp(
  timestamp: null | string | number | Moment,
  format: string,
  nullString: string
): string {
  return timestamp ? moment(timestamp).format(format) : nullString
}

export function getMomentFromNow(addHours: number): Moment {
  return moment().add(addHours, `hours`).set({ minutes: 0, seconds: 0, milliseconds: 0 })
}

export function formatDateTimeToLocalizedString(dateTimeStr: string, useNiceNames?: boolean, includeHours = true, monthsAsNumbers = false): string {
  const REFERENCE = moment()
  const TODAY = REFERENCE.clone().startOf(`day`)
  const YESTERDAY = REFERENCE.clone().subtract(1, `days`).startOf(`day`)
  const TOMORROW = REFERENCE.clone().add(1, `days`).startOf(`day`)

  const timeObj = moment(dateTimeStr)

  let str = ``
  if (timeObj.isSame(TODAY, `d`) && useNiceNames) {
    str += i18n.t(`Today `)
  } else if (timeObj.isSame(YESTERDAY, `d`) && useNiceNames) {
    str += i18n.t(`Yesterday `)
  } else if (timeObj.isSame(TOMORROW, `d`) && useNiceNames) {
    str += i18n.t(`Tomorrow`) + ` `
  } else {
    switch (timeObj.day()) {
      case 0:
        str += i18n.t(`Sun `)
        break
      case 1:
        str += i18n.t(`Mon `)
        break
      case 2:
        str += i18n.t(`Tue `)
        break
      case 3:
        str += i18n.t(`Wed `)
        break
      case 4:
        str += i18n.t(`Thu `)
        break
      case 5:
        str += i18n.t(`Fri `)
        break
      case 6:
        str += i18n.t(`Sat `)
        break
      default:
        str += ` `
        break
    }

    str += timeObj.format(`D`)

    if (monthsAsNumbers) {
      str += `/${timeObj.month() + 1}`
    } else {
      switch (timeObj.month()) {
        case 0:
          str += ` ${i18n.t('Jan ')}`
          break
        case 1:
          str += ` ${i18n.t('Feb ')}`
          break
        case 2:
          str += ` ${i18n.t('Mar ')}`
          break
        case 3:
          str += ` ${i18n.t('Apr ')}`
          break
        case 4:
          str += ` ${i18n.t('May ')}`
          break
        case 5:
          str += ` ${i18n.t('Jun ')}`
          break
        case 6:
          str += ` ${i18n.t('Jul ')}`
          break
        case 7:
          str += ` ${i18n.t('Aug ')}`
          break
        case 8:
          str += ` ${i18n.t('Sep ')}`
          break
        case 9:
          str += ` ${i18n.t('Oct ')}`
          break
        case 10:
          str += ` ${i18n.t('Nov ')}`
          break
        case 11:
          str += ` ${i18n.t('Dec ')}`
          break
        default:
          str += ``
          break
      }
    }

    if (timeObj.year() !== REFERENCE.year()) {
      str += timeObj.format(`YYYY`) + ` `
    }
  }

  if (includeHours){
    str += timeObj.format(`HH:mm`)
  }

  return str
}

export function roundToNearestHour(time: string | Moment): Moment {
  const moment_time = moment(time)
  return moment_time.get(`minute`) > 30 ? moment_time.startOf(`h`).add(1, `h`) : moment_time.startOf(`h`)
}

export function isValidISODateTime(str: string | number | undefined): boolean{
  return moment(str, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid()
}

export function getValidISODateTime(time: string | number | undefined): ISODateTime {
  let dt = new Datetime(Datetime.getISONow(0))

  if(isValidISODateTime(time)){
    dt = new Datetime(time)
  }

  return dt
}

export function formatStartTime(t, startTime: string): string {
  let start_time
  const tomorrowDate = Datetime.getTomorrowDate()
  if (startTime?.toString().includes(moment().year().toString())) {
    start_time = `${moment(startTime).format(`ddd D MMM HH:mm`)}`
  } else {
    start_time = `${moment(startTime).format(`ddd D MMM, YYYY, HH:mm`)}`
  }

  if (Datetime.isBefore(Datetime.toISOString(startTime), tomorrowDate.endTime) && Datetime.isAfter(Datetime.toISOString(startTime), Datetime.getISONow())) {
    start_time = `${t('tomorrow')} ${moment(startTime).format(`HH:mm`)}`
  }

  return start_time
}