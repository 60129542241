import { getCurrency } from 'helpers/global.helper/global.helper'

export const reviewForecasts: UiConfig = {
  display_name: 'Review Forecasts',
  component: 'review_forecasts',
  id: -21,
  uid: -21,
  children_ids: [-31, -30, -22],
  props: {
    tabs: [{
      'cards': [
        {
          'title': 'Heat',
          'children_list': [
            {
              'ui_config_id': -31,
              'override_alias': {
                'key': '$key',
                'unit': 'heat',
              },
            },
            {
              'ui_config_id': -30,
              'override_alias': {
                'key': '$key',
                'unit': 'heat',
              },
            },
          ],
        },
      ],
      'title': '',
    },
    ],
  },
  dataset_instructions: [],
  alias: {
  },
}

export const reviewForecastChart: UiConfig = {
  display_name: '[chart] Review Forecast Chart',
  component: 'chart',
  id: -30,
  uid: -30,
  props: {
    items: [
      {
        fill: false,
        unit: '$data_unit',
        color: '$color-heatload',
        order: 0,
        title: '__Outcome',
        dashed: false,
        data_id: 'ground_truth',
        decimals: 1,
        data_type: '$data_type',
        y_axis_id: 'y',
      },
      {
        fill: true,
        opacity: true,
        unit: '$data_unit',
        color: '$light_red',
        order: 0,
        title: '__Outcome (outlier)',
        dashed: true,
        data_id: 'ground_truth_outlier',
        decimals: 1,
        data_type: '$data_type',
        y_axis_id: 'y',
      },
      {
        fill: false,
        unit: '$data_unit',
        color: '#000',
        order: 1,
        title: '__Forecast rolling',
        dashed: true,
        data_id: 'active_value',
        decimals: 1,
        data_type: '$data_type',
        y_axis_id: 'y',
      },
      {
        unit: '$data_unit',
        color: '$color-el',
        order: 2,
        title: '__Forecast when sending electricity plan',
        data_id: 'forecast_frozen_for_elplan',
        tooltip: '',
        decimals: 1,
        data_type: '$data_type',
      },
      {
        fill: false,
        unit: '$data_unit',
        color: '$color-24h-forecast',
        order: 3,
        title: '__Forecast 24h before',
        dashed: true,
        data_id: 'forecast_24h_before',
        decimals: 1,
        data_type: '$data_type',
        y_axis_id: 'y',
        default_hidden: true,
      },
      {
        fill: false,
        unit: '$data_unit',
        color: '$color-48h-forecast',
        order: 4,
        title: '__Forecast 48h before',
        dashed: true,
        data_id: 'forecast_48h_before',
        decimals: 1,
        data_type: '$data_type',
        y_axis_id: 'y',
        default_hidden: true,
      },
      {
        fill: false,
        unit: '$data_unit',
        color: '$color-168h-forecast',
        order: 5,
        title: '__Forecast 168h before',
        dashed: true,
        data_id: 'forecast_168h_before',
        decimals: 1,
        data_type: '$data_type',
        y_axis_id: 'y',
        default_hidden: true,
      },
      {
        fill: false,
        unit: '$data_unit',
        color: '$color-2w-forecast',
        order: 6,
        title: '__Forecast 2w before',
        dashed: true,
        data_id: 'forecast_336h_before',
        decimals: 1,
        data_type: '$data_type',
        y_axis_id: 'y',
        default_hidden: true,
      },
      {
        unit: '__Accuracy [%]',
        color: '$color-1',
        title: '__Accuracy rolling',
        data_id: 'accuracy_active_value',
        tooltip: '',
        decimals: 1,
        show_only_in_export: true,
      },
      {
        unit: '__Accuracy [%]',
        color: '$color-1',
        title: '__Accuracy 12h before',
        data_id: 'accuracy_forecast_12h_before',
        tooltip: '',
        decimals: 1,
        show_only_in_export: true,
      },
      {
        unit: '__Accuracy [%]',
        color: '$color-1',
        title: '__Accuracy 24h before',
        data_id: 'accuracy_forecast_24h_before',
        tooltip: '',
        decimals: 1,
        show_only_in_export: true,
      },
      {
        unit: '__Accuracy [%]',
        color: '$color-1',
        title: '__Accuracy 48h before',
        data_id: 'accuracy_forecast_48h_before',
        tooltip: '',
        decimals: 1,
        show_only_in_export: true,
      },
      {
        unit: '__Accuracy [%]',
        color: '$color-1',
        title: '__Accuracy when sending electricity plan',
        data_id: 'accuracy_forecast_frozen_for_elplan',
        tooltip: '',
        decimals: 1,
        show_only_in_export: true,
      },
      {
        unit: '$data_unit',
        color: '$color-1',
        title: '__MAE rolling',
        data_id: 'absolute_error_active_value',
        tooltip: 'Mean absolute error',
        decimals: 1,
        data_type: '$data_type',
        show_only_in_export: true,
      },
      {
        unit: '$data_unit',
        color: '$color-1',
        title: '__MAE 12h before',
        data_id: 'absolute_error_forecast_12h_before',
        tooltip: 'Mean absolute error',
        decimals: 1,
        data_type: '$data_type',
        show_only_in_export: true,
      },
      {
        unit: '$data_unit',
        color: '$color-1',
        title: '__MAE 24h before',
        data_id: 'absolute_error_forecast_24h_before',
        tooltip: 'Mean absolute error',
        decimals: 1,
        data_type: '$data_type',
        show_only_in_export: true,
      },
      {
        unit: '$data_unit',
        color: '$color-1',
        title: '__MAE 48h before',
        data_id: 'absolute_error_forecast_48h_before',
        tooltip: 'Mean absolute error',
        decimals: 1,
        data_type: '$data_type',
        show_only_in_export: true,
      },
      {
        unit: '$data_unit',
        color: '$color-1',
        title: '__MAE when sending electricity plan',
        data_id: 'absolute_error_forecast_frozen_for_elplan',
        tooltip: 'Mean absolute error',
        decimals: 1,
        data_type: '$data_type',
        show_only_in_export: true,
      },
    ],
    title: '',
    config: {
      options: {
        scales: {
          y: {
            grid: {
              display: true,
              tickWidth: 2,
              borderDash: [
                3,
                5,
              ],
              borderColor: '$grey',
              borderWidth: 2,
            },
            title: {
              text: '$data_unit',
              display: true,
            },
            display: true,
          },
        },
        plugins: {
          todayMarker: {
            enabled: false,
          },
          chartLegend: {
            display: true,
            shared: true,
            groupId: 'review_forecast',
          },
          syncYAxis: {
            enabled: false,
          },
        },
      },
    },
  },
  dataset_instructions: [
    {
      type: 'review_forecast',
      filter: {
        end_time: '$end_time',
        aggregate: '',
        start_time: '$start_time',
        offset_end_time: '$offset_end_time',
        offset_start_time: '$offset_start_time',
        aggregate_in_frontend: true,
      },
      contract: {
        key: '$key',
        unit: '$unit',
        sources: [
          {
            source: 'ground_truth',
            return_id: 'ground_truth',
          },
          {
            source: 'ground_truth_outlier',
            return_id: 'ground_truth_outlier',
          },
          {
            source: 'active_value',
            return_id: 'active_value',
          },
          {
            source: 'forecast_24h_before',
            return_id: 'forecast_24h_before',
          },
          {
            source: 'forecast_48h_before',
            return_id: 'forecast_48h_before',
          },
          {
            source: 'forecast_168h_before',
            return_id: 'forecast_168h_before',
          },
          {
            source: 'forecast_240h_before',
            return_id: 'forecast_240h_before',
          },
          {
            source: 'forecast_336h_before',
            return_id: 'forecast_336h_before',
          },
          {
            source: 'accuracy_active_value',
            return_id: 'accuracy_active_value',
          },
          {
            source: 'accuracy_forecast_24h_before',
            return_id: 'accuracy_forecast_24h_before',
          },
          {
            source: 'accuracy_forecast_48h_before',
            return_id: 'accuracy_forecast_48h_before',
          },
          {
            source: 'accuracy_forecast_168h_before',
            return_id: 'accuracy_forecast_168h_before',
          },
          {
            source: 'accuracy_forecast_240h_before',
            return_id: 'accuracy_forecast_240h_before',
          },
          {
            source: 'accuracy_forecast_336h_before',
            return_id: 'accuracy_forecast_336h_before',
          },
          {
            source: 'accuracy_forecast_frozen_for_elplan',
            return_id: 'accuracy_forecast_frozen_for_elplan',
          },
          {
            source: 'forecast_frozen_for_elplan',
            return_id: 'forecast_frozen_for_elplan',
          },
          {
            source: 'absolute_error_active_value',
            return_id: 'absolute_error_active_value',
          },
          {
            source: 'absolute_error_forecast_24h_before',
            return_id: 'absolute_error_forecast_24h_before',
          },
          {
            source: 'absolute_error_forecast_48h_before',
            return_id: 'absolute_error_forecast_48h_before',
          },
          {
            source: 'absolute_error_forecast_168h_before',
            return_id: 'absolute_error_forecast_168h_before',
          },
          {
            source: 'absolute_error_forecast_240h_before',
            return_id: 'absolute_error_forecast_240h_before',
          },
          {
            source: 'absolute_error_forecast_336h_before',
            return_id: 'absolute_error_forecast_336h_before',
          },
          {
            source: 'absolute_error_forecast_frozen_for_elplan',
            return_id: 'absolute_error_forecast_frozen_for_elplan',
          },
        ],
      },
      return_id: '',
    },
  ],
  alias: {
    key: '$key',
    unit: '$unit',
    data_unit: 'MW',
    data_type: 'power',
  },
}

export const reviewAccuracyKpi: UiConfig = {
  display_name: '',
  component: 'double_kpi_value',
  id: -31,
  uid: -31,
  props: {
    items: [
      {
        title: '__Accuracy rolling',
        values: [
          {
            text: '',
            type: 'main',
            unit: '%',
            data_id: 'accuracy_active_value',
            decimals: 2,
            data_type: '',
          },
          {
            text: 'MAE: ',
            type: 'sub',
            unit: '$data_unit',
            data_id: 'absolute_error_active_value',
            decimals: 2,
            data_type: '$data_type',
          },
        ],
        tooltip: '',
        ignore_zoom: false,
      },
      {
        title: '__Accuracy when sending electricity plan',
        values: [
          {
            text: '',
            type: 'main',
            unit: '%',
            data_id: 'accuracy_forecast_frozen_for_elplan',
            decimals: 2,
            data_type: '',
          },
          {
            text: 'MAE: ',
            type: 'sub',
            unit: '$data_unit',
            data_id: 'absolute_error_forecast_frozen_for_elplan',
            decimals: 2,
            data_type: '$data_type',
          },
        ],
        tooltip: '',
        ignore_zoom: false,
      },
      {
        title: '__Accuracy 24h before',
        values: [
          {
            text: '',
            type: 'main',
            unit: '%',
            data_id: 'accuracy_forecast_24h_before',
            decimals: 2,
            data_type: '',
          },
          {
            text: 'MAE: ',
            type: 'sub',
            unit: '$data_unit',
            data_id: 'absolute_error_forecast_24h_before',
            decimals: 2,
            data_type: '$data_type',
          },
        ],
        tooltip: '',
        ignore_zoom: false,
      },
      {
        title: '__Accuracy 48h before',
        values: [
          {
            text: '',
            type: 'main',
            unit: '%',
            data_id: 'accuracy_forecast_48h_before',
            decimals: 2,
            data_type: '',
          },
          {
            text: 'MAE: ',
            type: 'sub',
            unit: '$data_unit',
            data_id: 'absolute_error_forecast_48h_before',
            decimals: 2,
            data_type: '$data_type',
          },
        ],
        tooltip: '',
        ignore_zoom: false,
      },
      {
        title: '__Accuracy 2w before',
        values: [
          {
            text: '',
            type: 'main',
            unit: '%',
            data_id: 'accuracy_forecast_336h_before',
            decimals: 2,
            data_type: '',
          },
          {
            text: 'MAE: ',
            type: 'sub',
            unit: '$data_unit',
            data_id: 'absolute_error_forecast_336h_before',
            decimals: 2,
            data_type: '$data_type',
          },
        ],
        tooltip: '',
        ignore_zoom: false,
      },
    ],
    title: '',
    position: 'left',
    paddingLeft: true,
    show_date_range: false,
    show_zoom_buttons: false,
    has_background_color: false,
    show_included_outcome: false,
    applyAggregateOptions: {
      skipInvalidIndices: true,
    },
  },
  dataset_instructions: [
    {
      type: 'review_forecast',
      filter: {
        end_time: '$end_time',
        aggregate: 'mean',
        start_time: '$start_time',
        offset_end_time: '$offset_end_time',
        offset_start_time: '$offset_start_time',
        aggregate_in_frontend: true,
      },
      contract: {
        key: '$key',
        unit: '$unit',
        sources: [
          {
            source: 'ground_truth',
            return_id: 'ground_truth',
          },
          {
            source: 'ground_truth_outlier',
            return_id: 'ground_truth_outlier',
          },
          {
            source: 'active_value',
            return_id: 'active_value',
          },
          {
            source: 'forecast_24h_before',
            return_id: 'forecast_24h_before',
          },
          {
            source: 'forecast_48h_before',
            return_id: 'forecast_48h_before',
          },
          {
            source: 'forecast_168h_before',
            return_id: 'forecast_168h_before',
          },
          {
            source: 'forecast_240h_before',
            return_id: 'forecast_240h_before',
          },
          {
            source: 'forecast_336h_before',
            return_id: 'forecast_336h_before',
          },
          {
            source: 'accuracy_active_value',
            return_id: 'accuracy_active_value',
          },
          {
            source: 'accuracy_forecast_24h_before',
            return_id: 'accuracy_forecast_24h_before',
          },
          {
            source: 'accuracy_forecast_48h_before',
            return_id: 'accuracy_forecast_48h_before',
          },
          {
            source: 'accuracy_forecast_168h_before',
            return_id: 'accuracy_forecast_168h_before',
          },
          {
            source: 'accuracy_forecast_240h_before',
            return_id: 'accuracy_forecast_240h_before',
          },
          {
            source: 'accuracy_forecast_336h_before',
            return_id: 'accuracy_forecast_336h_before',
          },
          {
            source: 'accuracy_forecast_frozen_for_elplan',
            return_id: 'accuracy_forecast_frozen_for_elplan',
          },
          {
            source: 'forecast_frozen_for_elplan',
            return_id: 'forecast_frozen_for_elplan',
          },
          {
            source: 'absolute_error_active_value',
            return_id: 'absolute_error_active_value',
          },
          {
            source: 'absolute_error_forecast_24h_before',
            return_id: 'absolute_error_forecast_24h_before',
          },
          {
            source: 'absolute_error_forecast_48h_before',
            return_id: 'absolute_error_forecast_48h_before',
          },
          {
            source: 'absolute_error_forecast_168h_before',
            return_id: 'absolute_error_forecast_168h_before',
          },
          {
            source: 'absolute_error_forecast_240h_before',
            return_id: 'absolute_error_forecast_240h_before',
          },
          {
            source: 'absolute_error_forecast_336h_before',
            return_id: 'absolute_error_forecast_336h_before',
          },
          {
            source: 'absolute_error_forecast_frozen_for_elplan',
            return_id: 'absolute_error_forecast_frozen_for_elplan',
          },
        ],
      },
      return_id: '',
    },
  ],
  alias: {
    key: '$key',
    unit: '$unit',
    data_unit: 'MW',
    data_type: 'power',
  },

}

export const reviewForecastTemperatureChart: UiConfig = {
  display_name: `[chart] Review temperature forecast`,
  component: `chart`,
  id: -22,
  uid: -22,
  props: {
    items: [
      {
        fill: false,
        unit: '°C',
        color: '$color-temp',
        order: 0,
        title: '__Temperature outcome ',
        dashed: false,
        data_id: 'temp_outcome',
        decimals: 1,
        data_type: '',
        y_axis_id: 'y',
      },
      {
        fill: false,
        unit: '°C',
        color: '$color-temp',
        order: 0,
        title: '__Temperature forecast',
        dashed: true,
        data_id: 'temp_forecast',
        decimals: 1,
        data_type: '',
        y_axis_id: 'y',
      },
    ],
    title: '',
    config: {
      options: {
        scales: {
          x: {
            grid: {
              tickWidth: 2,
              borderColor: '$grey',
              borderWidth: 2,
            },
          },
          y: {
            grid: {
              display: true,
              tickWidth: 2,
              borderDash: [
                3,
                5,
              ],
              borderColor: '$grey',
              borderWidth: 2,
            },
            title: {
              text: '°C',
              display: true,
            },
            display: true,
          },
        },
        plugins: {
          todayMarker: {
            enabled: false,
          },
          chartLegend: {
            display: true,
            groupId: '',
          },
        },
      },
    },
    base_items: {
      all: false,
    },
  },
  dataset_instructions: [
    {
      type: 'meas',
      filter: {
        end_time: '$end_time',
        aggregate: '',
        start_time: '$start_time',
        offset_end_time: '$offset_end_time',
        offset_start_time: '$offset_start_time',
      },
      contract: {
        tag: 'temperature',
        locality: '$forecast_locality',
        entry_identifier_prefix: '',
      },
      return_id: 'temp_forecast',
    },
    {
      type: 'meas',
      filter: {
        end_time: '$end_time',
        aggregate: '',
        start_time: '$start_time',
        offset_end_time: '$offset_end_time',
        offset_start_time: '$offset_start_time',
      },
      contract: {
        tag: '$outcome_tag',
        locality: '$outcome_locality',
        entry_identifier_prefix: '',
      },
      return_id: 'temp_outcome',
    },
  ],
  alias: {
    outcome_locality: 'meas_se_se',
    forecast_locality: '',
    outcome_tag: ''},
}

export const reviewForecastElectricityPriceChart: UiConfig = {
  display_name: `[chart] Review Forecast Electricity Price`,
  component: `chart`,
  id: -23,
  uid: -23,
  props: {
    items: [
      {
        fill: false,
        unit: ``,
        color: `$color-el`,
        order: 0,
        title: `__Electricity price forecast (Montel)`,
        dashed: true,
        data_id: `electricity_price_forecast_montel`,
        decimals: 1,
        data_type: `cost/energy`,
        y_axis_id: `y`,
      },
      {
        fill: false,
        unit: ``,
        color: `$color-el`,
        order: 1,
        title: `__Electricity price forecast (Volue)`,
        dashed: true,
        data_id: `electricity_price_forecast_volue`,
        decimals: 1,
        data_type: `cost/energy`,
        y_axis_id: `y`,
      },
      {
        fill: false,
        unit: ``,
        color: `$color-el`,
        order: 2,
        title: `__Electricity price outcome `,
        data_id: `el_price_outcome`,
        decimals: 1,
        data_type: `cost/energy`,
        y_axis_id: `y`,
      },
    ],
    title: ``,
    config: {
      options: {
        scales: {
          x: {
            grid: {
              tickWidth: 2,
              borderColor: '$grey',
              borderWidth: 2,
            },
          },
          y: {
            grid: {
              display: true,
              tickWidth: 2,
              borderDash: [
                3,
                5,
              ],
              borderColor: '$grey',
              borderWidth: 2,
            },
            title: {
              text: `${getCurrency()}/MWh`,
              display: true,
            },
            display: true,
          },
        },
        plugins: {
          todayMarker: {
            enabled: false,
          },
          chartLegend: {
            display: true,
            groupId: '',
          },
        },
      },
    },
  },
  dataset_instructions: [
    {
      type: `meas`,
      filter: {
        end_time: '$end_time',
        aggregate: '',
        start_time: '$start_time',
        offset_end_time: '$offset_end_time',
        offset_start_time: '$offset_start_time'},
      contract: {
        tag: `$tag_montel`,
        locality: `meas_se_se`,
        entry_identifier_prefix: ``,
      },
      return_id: `electricity_price_forecast_montel`,
    },
    {
      type: `meas`,
      filter: {
        end_time: '$end_time',
        aggregate: '',
        start_time: '$start_time',
        offset_end_time: '$offset_end_time',
        offset_start_time: '$offset_start_time'},
      contract: {
        tag: `$tag_volue`,
        locality: `meas_se_se`,
        entry_identifier_prefix: ``,
      },
      return_id: `electricity_price_forecast_volue`,
    },
    {
      type: `meas`,
      filter: {
        end_time: '$end_time',
        aggregate: '',
        start_time: '$start_time',
        offset_end_time: '$offset_end_time',
        offset_start_time: '$offset_start_time'},
      contract: {
        tag: `spot`,
        locality: `$electricity_price_locality`,
        entry_identifier_prefix: ``,
      },
      return_id: `el_price_outcome`,
    },
  ],
  alias: {
    electricity_price_locality: 'meas_se_se',
    tag_volue: 'el_price_forecast_volue',
    tag_montel: 'el_price_forecast_montel',
  },
}
