import {
  productionPlanForecastChart,
  productionPlanForecastExclElChart,
  productionPlanChart,
  productionPlanKpi,
  followupForecastChart,
  productionPlanTable,
  realTimeOptimizationBars,
  sandboxForecast,
  shiftHandoverProdPlanChart,
  sandboxProductionKpi,
  sandboxProductionPlan,
  sandboxProductionResult,
  followupProdOptimalChart,
  followupInclusiveDeviationsOutcomeChart,
  followupProdPlanOutcomeChart,
  followupExclusiveDeviationsOutcomeChart,
  followupProdTotalEnergyHorizontalBars,
  followupProdTotalCostHorizontalBars,
  followupProductionKpi,
  shiftHandoverProdPlan,
  followupProdOptimalExclRatedPowerChart,
  followupProdPlanOutcomeExclRatedPowerChart,
  followupExclusiveDeviationsOutcomeExclRatedPowerChart,
  followupInclusiveDeviationsOutcomeExklRatedPowerChart,
  marginalCostChart,
  marginalCostKpi,
  reviewForecastChart,
  reviewAccuracyKpi,
  reviewForecasts,
  elplanBids,
} from 'ui/uiConfig/template/templateUiConfigs'

import { clone } from 'helpers/global.helper/global.helper'

import { reviewForecastElectricityPriceChart, reviewForecastTemperatureChart } from './moduleTemplates/reviewForecasts'

type uiConfigComponentsWithDefaults = 'production_plan_forecast' | 'optimize_production_plan' | 'followup_forecast' | 'production_plan_table' | 'sandbox_forecast' | 'shift_handover_chart' | 'sandbox_production_plan' | 'followup_production' | 'follow_up_prod_kpi' | 'marginal_cost_chart' | 'marginal_cost_kpi' | 'review_forecasts' | 'review_temperature_forecast' | 'review_el_price_forecast' | 'availability' | 'elplan_bids'

const defaultChildrenTemplates: Record<uiConfigComponentsWithDefaults, UiConfig[]> = {
  production_plan_forecast: [productionPlanForecastChart],
  optimize_production_plan: [productionPlanKpi, productionPlanChart],
  followup_forecast: [followupForecastChart],
  review_temperature_forecast: [reviewForecastTemperatureChart],
  review_el_price_forecast: [reviewForecastElectricityPriceChart],
  production_plan_table: [productionPlanTable],
  sandbox_forecast: [],
  sandbox_production_plan: [sandboxProductionKpi, sandboxProductionPlan],
  shift_handover_chart: [shiftHandoverProdPlanChart],
  marginal_cost_chart: [],
  marginal_cost_kpi: [],
  follow_up_prod_kpi: [],
  review_forecasts: [reviewForecasts, reviewForecastChart, reviewAccuracyKpi],
  followup_production: [followupProdOptimalChart, followupInclusiveDeviationsOutcomeChart, followupProdPlanOutcomeChart, followupExclusiveDeviationsOutcomeChart, followupProdTotalEnergyHorizontalBars, followupProdTotalCostHorizontalBars],
  availability: [],
  elplan_bids: [],
}
const additionalTemplates: UiConfig[] = [
  productionPlanForecastExclElChart,
  followupProdOptimalExclRatedPowerChart,
  followupProdPlanOutcomeExclRatedPowerChart,
  followupExclusiveDeviationsOutcomeExclRatedPowerChart,
  followupInclusiveDeviationsOutcomeExklRatedPowerChart,
]

const defaultTemplate: Record<string, UiConfig> = {
  sandbox_forecast: sandboxForecast,
  shift_handover_chart: shiftHandoverProdPlan,
  follow_up_prod_kpi: followupProductionKpi,
  review_forecasts: reviewForecasts,
  sandbox_production_plan: sandboxProductionResult,
  marginal_cost_chart: marginalCostChart,
  marginal_cost_kpi: marginalCostKpi,
  real_time_optimization_bars: realTimeOptimizationBars,
  review_accuracy_kpi: reviewAccuracyKpi,
  elplan_bids: elplanBids,
}

const parentComponentToIds: Record<string, number[]> = {}

export function initializeTemplates(addUiConfig: (uiConfig: UiConfig) => void): void {
  const componentNamesWithDefaults = Object.keys(defaultChildrenTemplates) as uiConfigComponentsWithDefaults[]

  componentNamesWithDefaults.forEach((component, i) => {
    parentComponentToIds[component] = [1]

    if (!defaultChildrenTemplates[component]) {
      return
    }

    defaultChildrenTemplates[component].forEach((template, j) => {
      const uid = template.uid || -1 * ((i + 1) * 1000 + j)
      const uiConfig: UiConfig = {
        ...clone(template),
        children_ids: [],
        id: uid,
        uid: uid,
        version: 0,
        system: null,
        is_published: true,
      }

      parentComponentToIds[component].push(uid)
      addUiConfig(uiConfig)
    })
  })

  additionalTemplates.forEach((template, i) => {
    const uid = template.uid || -1 * (100 + i)

    const uiConfig: UiConfig = {
      ...clone(template),
      children_ids: [],
      id: uid,
      uid: uid,
      version: 0,
      system: null,
      is_published: true,
    }

    addUiConfig(uiConfig)
  })
}

export function getDefaultChildrenIds(parentComponent: string): number[] {
  return parentComponentToIds[parentComponent] || []
}

export function getDefaultTemplate(parentComponent: string): UiConfig | null {
  const t = defaultTemplate[parentComponent] || null

  if (t?.call) {
    return t()
  }

  return t
}

export function applyDefaultTemplate(_uiConfig: UiConfig): UiConfig {
  const template = getDefaultTemplate(_uiConfig.component)

  if (!template) {
    return _uiConfig
  }

  const uiConfig = clone(_uiConfig)

  uiConfig.props = {
    ...(uiConfig.props || {}),
    ...(template.props || {}),
  }

  uiConfig.dataset_instructions = [
    ...(uiConfig.dataset_instructions || []),
    ...(template.dataset_instructions || []),
  ]

  uiConfig.alias = {
    ...(template.alias || {}),
    ...(uiConfig.alias || {}),
  }

  uiConfig.display_name = uiConfig.display_name || template.display_name || ''

  return uiConfig
}