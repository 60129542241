import { reFetchAllDatasets } from 'api/dataset/dataset.api'
import { getDefaultTimes, setZoom } from 'store/zoom/zoom'

let datasetScheduler: NodeJS.Timer | null = null

function datasetRefetchEveryHourScheduler(): void {
  if (datasetScheduler) {
    clearInterval(datasetScheduler)
  }

  const now = new Date()
  const nextHour = new Date()
  const HOUR_IN_MILLISECONDS = 60 * 60 * 1000
  const MIN_INIT_REFETCH_DELAY = 1 * 60 * 1000
  const MAX_DELAY_MILLISECONDS = 2 * 60 * 1000
  const RANDOM_DELAY_TO_SPREAD_OUT_FETCHES = Math.floor(Math.random() * MAX_DELAY_MILLISECONDS)
  const DELAY_AFTER_WHOLE_HOUR = 30 * 1000 + RANDOM_DELAY_TO_SPREAD_OUT_FETCHES

  nextHour.setMinutes(0)
  nextHour.setSeconds(0)
  nextHour.setTime(nextHour.getTime() + HOUR_IN_MILLISECONDS)

  let timeDiff = nextHour.getTime() - now.getTime()

  if (timeDiff < MIN_INIT_REFETCH_DELAY) {
    timeDiff += HOUR_IN_MILLISECONDS + MIN_INIT_REFETCH_DELAY
  }
  timeDiff += DELAY_AFTER_WHOLE_HOUR

  setTimeout(() => {
    reFetchAllDatasets()

    datasetScheduler = setInterval(reFetchAllDatasets, HOUR_IN_MILLISECONDS)
  }, timeDiff)
}

let zoomScheduler: NodeJS.Timer | null = null
function resetZoomEveryHourScheduler(): void {
  if (zoomScheduler) {
    clearInterval(zoomScheduler)
  }

  function newHourHandler(): void {
    // Only reset zoom on optimize view on new hour
    const { startTime, endTime } = getDefaultTimes('optimize')
    setZoom(startTime, endTime, 'optimize')
  }

  const now = new Date()
  const nextHour = new Date()
  const HOUR_IN_MILLISECONDS = 60 * 60 * 1000

  nextHour.setMinutes(0)
  nextHour.setSeconds(0)
  nextHour.setTime(nextHour.getTime() + HOUR_IN_MILLISECONDS)

  const timeDiff = nextHour.getTime() - now.getTime()

  setTimeout(() => {
    newHourHandler()

    zoomScheduler = setInterval(newHourHandler, HOUR_IN_MILLISECONDS)
  }, timeDiff)
}

export default function init(): void {
  datasetRefetchEveryHourScheduler()
  resetZoomEveryHourScheduler()
}
