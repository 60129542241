import React, { ReactElement, useEffect } from 'react'

import { GlobalStyles, useTheme } from '@mui/material'
import { postStat } from 'api/stats/stats.api'
import zoomStore from 'store/zoom/zoom'
import { useAlert } from 'ui/components/AlertContext/AlertContext'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

import { history } from 'helpers/history'

import AuthenticatedApp from './components/AuthenticatedApp/AuthenticatedApp'
import UnauthenticatedApp from './components/UnauthenticatedApp/UnauthenticatedApp'

function App(): ReactElement {
  const { alertClear } = useAlert()

  useEffect(() => {
    history.listen((location) => {
      // clear alerts on location change
      alertClear()

      const route = location?.pathname?.split(`/`)?.[1] ?? ``
      zoomStore.route = route

      if (route) {
        postStat(route, `navigate-to`)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { user } = useAuth()
  return user ? <AuthenticatedApp /> : <UnauthenticatedApp />
}

export default function AppWithStyles(): ReactElement {
  const theme = useTheme()

  const styles = {
    body: {
      fontFamily: theme.typography.fontFamily,
    },
    // Change scrollbar style
    '*::-webkit-scrollbar': {
      borderRadius: `10px`,
      width: `10px`,
      height: `10px`,
    },
    '*::-webkit-scrollbar-track': {
      borderRadius: `10px`,
      backgroundColor: `rgba(0,0,0,.1)`,
      // '-webkit-box-shadow': `inset 0 0 6px rgba(0,0,0,0.3)`,
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: `rgba(0,0,0,.2)`,
      borderRadius: `10px`,
    },
    '*::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(0,0,0,.5)',
    },
  }

  return (
    <>
      <GlobalStyles styles={styles} />
      <App />
    </>
  )
}