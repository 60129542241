import React, { ReactElement, useState } from 'react'

import { useChannel, useEvent } from '@harelpls/use-pusher'
import { Box, Tab as BaseTab, Tabs, Typography, styled } from '@mui/material'
import { NOTIFICATIONS_QUERY_KEY, useNotifications } from 'api/notifications/notifications.api'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { ArrowDatePicker } from 'ui/molecules/pickers/ArrowDatePicker/ArrowDatePicker'
import Datetime from 'utils/datetime/datetime'

import { queryClient } from 'helpers/queryClient'

import { NotificationType, getNotificationItem } from './NotificationCenter.helper'
import styles from './NotificationCenter.module.less'

const Tab = styled(BaseTab)({
  textTransform: 'uppercase',
})

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps): ReactElement {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function NotificationCenter(): ReactElement {

  const [value, setValue] = React.useState(0)
  const [date, setDate] = useState<ISODateTime>(Datetime.getISONow())

  const { t } = useTranslation()
  const { data: response } = useNotifications({
    startTime: Datetime.getStartOfDay(date),
    endTime: Datetime.getEndOfDay(date),
    type: value === 0 ? 'optimization' : '',
    exclude: value === 1 ? 'optimization' : ''})
  let dataResponse: NotificationType[] = response
  const { systemId } = useAuth()

  const channel = useChannel(systemId ? `notification-center-${systemId}` : undefined)

  useEvent(channel, `notification-create`, () => {
    queryClient.invalidateQueries(NOTIFICATIONS_QUERY_KEY)
  })
  useEvent(channel, `notification-update`, () => {
    queryClient.invalidateQueries(NOTIFICATIONS_QUERY_KEY)
  })
  useEvent(channel, `notification-delete`, () => {
    queryClient.invalidateQueries(NOTIFICATIONS_QUERY_KEY)
  })

  const history = useHistory()

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue)
  }

  function onLinkClicked(route: string): void {
    // const newRoute = decodeURIComponent(route) //TODO ta bort %skiten
    history.push(route)
  }

  if (!response) {
    dataResponse = []
  }

  const runningResponseOptimizationItems: NotificationType[] = dataResponse.filter((item) => item.meta?.status === 'running' || item.meta?.status === 'queued')
  const finishedOrFailedOptimizationResponseItems: NotificationType[] = dataResponse.filter((item) => item.meta?.status !== 'running' && item.meta?.status !== 'queued')

  return (
    <div className={styles.NotificationCenter}>
      <div className={styles.NotificationCenter_Header}>
        <div className={styles.NotificationCenter_Header_Title}>
          <div className={styles.NotificationCenter_Header_Title__beta}>{'Beta'}</div>
          {t('Events')}
        </div>
        <div className={styles.NotificationCenter_Header_CalendarContainer}>
          <ArrowDatePicker
            theme='dark'
            prevButtonTooltip={t(`Previous day`)}
            nextButtonTooltip={t(`Next day`)}
            format={`ddd D MMM`}
            step={{ unit: 'day', amount: 1 }}
            value={moment(date)}
            onChange={(date) => setDate(Datetime.toISOString(date))}
            maxValue={moment(Datetime.getTodayDate().endTime)}
          />
        </div>
      </div>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'sticky', top: '70px', backgroundColor: '#152029' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
            indicatorColor="secondary"
            textColor="inherit"
          >
            <Tab label={t('Optimizations')}{...a11yProps(0)} />
            <Tab label={t('Misc')} {...a11yProps(1)} />
          </Tabs>

        </Box>
        <CustomTabPanel value={value} index={0}>
          <div className={styles.NotificationCenter_Body}>
            <div className={styles.NotificationCenter_Events}>
              <text>{t('Running')}</text>
              {runningResponseOptimizationItems.length !== 0 ?
                runningResponseOptimizationItems.map((item, index) => { return <div key={index}>{getNotificationItem(item, onLinkClicked)}</div> }) :
                <div className={styles.NotificationCenter_Events__noEvents}>{t('No running optimizations')}</div>}
            </div>

            <div className={styles.NotificationCenter_Events}>
              <text>{t('Completed')}</text>
              <div className={styles.NotificationCenter_Events_Container}>
                {finishedOrFailedOptimizationResponseItems.length !== 0 ?
                  finishedOrFailedOptimizationResponseItems.map((item, index) => { return <div key={index}>{getNotificationItem(item, onLinkClicked)}</div> })
                  : <div className={styles.NotificationCenter_Events__noEvents}>{t('No completed optimizations')}</div>
                }
              </div>
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1} >
          <div className={styles.NotificationCenter_Body}>
            <div className={styles.NotificationCenter_Events}>
              {dataResponse.length !== 0 ?
                dataResponse.map((item, index) => { return <div key={index}>{getNotificationItem(item, onLinkClicked)}</div> }) :
                <div className={styles.NotificationCenter_Events__noEvents}>{t('No events')}</div>}
            </div>
          </div>
        </CustomTabPanel>
      </Box>


    </div>


  )
}
