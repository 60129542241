const DEFAULT_DATASET_TEMPLATES: Record<string, DatasetInstruction> = {
  temp_forecast: {
    type: `meas`,
    filter: {},
    contract: {
      tag: `temperature`,
      locality: `$weather_forecast_locality`,
      entry_identifier_prefix: ``,
    },
    return_id: `temp_forecast`,
  },
  temp_outcome: {
    type: `meas`,
    filter: {},
    contract: {
      tag: `$meas_temperature_tag`,
      locality: `$meas_locality`,
      entry_identifier_prefix: `meas_raw_`,
    },
    return_id: `temp_outcome`,
  },
  el_price_forecast: {
    type: `meas`,
    filter: {},
    contract: {
      tag: `$electricity_price_forecast_model`,
      locality: `$electricity_price_locality`,
      entry_identifier_prefix: ``,
    },
    return_id: `el_price_forecast`,
  },
  el_price_outcome: {
    type: `meas`,
    filter: {},
    contract: {
      tag: `spot`,
      locality: `$electricity_price_locality`,
      entry_identifier_prefix: ``,
    },
    return_id: `el_price_outcome`,
  },
  el_price: {
    type: `meas`,
    filter: {},
    contract: {
      tag: `value`,
      locality: `$electricity_price_locality`,
      entry_identifier_prefix: ``,
    },
    return_id: `el_price`,
  },
  heatload_forecast: {
    type: `meas`,
    filter: {},
    contract: {
      tag: `active_value`,
      locality: `$heatload_forecast_locality`,
      entry_identifier_prefix: ``,
    },
    return_id: `heatload_forecast`,
  },
  heatload_outcome: {
    type: `meas`,
    filter: {
      zero_is_nan: true,
    },
    contract: {
      tag: `$meas_heatload_tag`,
      locality: `$meas_locality`,
      entry_identifier_prefix: `meas_raw_`,
    },
    return_id: `heatload_outcome`,
  },
  base_unit_availability_vv: {
    type: `custom/base_unit_availability_vv`,
    contract: {
      fgc_units: [],
      system_id: `$system_id`,
      wasteheat_units: [],
      total_production_units: [],
    },
    return_id: `base_unit_availability_vv`,
  },
  otk_vv: {
    type: `custom/otk_vv`,
    contract: {
      system_id: `$system_id`,
    },
    return_id: `otk_vv`,
  },
  avv_ack: {
    type: `custom/charge_acc_with_peak_unit`,
    contract: {
      system_id: `$system_id`,
      unit_names: [],
    },
    return_id: `avv_ack`,
  },
  pps: {
    type: `pps`,
    filter: {
      zero_is_nan: true,
    },
    contract: {
      system_id: `$system_id`,
      positive_total: false,
    },
    return_id: `pps`,
  },
  mpk: {
    type: `pps`,
    filter: {
      zero_is_nan: true,
    },
    contract: {
      system_id: `$system_id`,
      positive_total: false,
    },
    return_id: `mpk`,
  },
  ddk: {
    type: `pps`,
    filter: {
      zero_is_nan: true,
    },
    contract: {
      system_id: `$system_id`,
      positive_total: false,
      optimal_opt_job_type_id: 3,
      outcome_opt_job_type_id: 5,
    },
    return_id: `ddk`,
  },
  avk: {
    type: `pps`,
    filter: {
      zero_is_nan: true,
    },
    contract: {
      system_id: `$system_id`,
      positive_total: false,
      optimal_opt_job_type_id: 4,
      outcome_opt_job_type_id: 3,
    },
    return_id: `avk`,
  },
  pps_prod_plan: {
    type: `pps`,
    filter: {
      zero_is_nan: true,
    },
    contract: {
      system_id: `$system_id`,
      positive_total: false,
      optimal_opt_job_type_id: 2,
      outcome_opt_job_type_id: 5,
    },
    return_id: `pps_prod_plan`,
  },
  rated_power: {
    type: `property_values`,
    contract: {
      opt_model_id: `$opt_model_id`,
      object_type: `node`,
      object_name: `main`,
      property_name: `rated_power`,
    },
    return_id: `rated_power`,
  },
}

export default DEFAULT_DATASET_TEMPLATES
