import { TransifexI18next } from '@transifex/i18next'
import { createNativeInstance, TxNative } from '@transifex/native'


interface CustomBackendOptions {
  tokens: string[]
}
class CustomBackend extends TransifexI18next{
  constructor(options: CustomBackendOptions) {
    super(options)
  }

  // eslint-disable-next-line
  init(services: any, options: CustomBackendOptions): void {
    this.txs = this.options.tokens.map((token: string) => {
      return createNativeInstance({token: token})
    })
  }

  read(language: string, namespace: string, callback: any): void {
    const txs: TxNative[] = this.txs
    const promises: Promise<void>[] = []
    txs.forEach((tx) => {
      promises.push(tx.fetchTranslations(language))
    })
    Promise.all(promises).then(() => {
      let data: {[key: string]: any } = { }
      txs.forEach((tx) => {
        data = {...data, ...this._convertPlurals(tx.cache.getTranslations(language))}
      })
      callback(null, data)
    }).catch((error) => {
      callback(error, null)
    })
  }
}

export default function createCustomBackend(options: CustomBackendOptions): typeof TransifexI18next {
  return new CustomBackend(options)
}
