import { colors } from 'styles/variables'
import Datetime from 'utils/datetime/datetime'

import { getCurrency } from 'helpers/global.helper/global.helper'

const currency = getCurrency()

export const DEFAULT_CLIENT_SIDE_ALIASES: UiConfigAliases = {
  'color-1': `#22adbe`,
  'color-2': `#1b72b8`,
  'color-3': `#14476d`,
  'color-4': `#2a3067`,
  'color-5': `#025933`,
  'color-6': `#92b023`,
  'color-7': `#C5BA00`,
  'color-8': `#f07f13`,
  'color-9': `#e72a80`,
  'color-10': `#b22d76`,
  'color-11': `#613178`,
  'color-12': `#261e38`,
  light_red: colors.danger2,
  red: colors.danger4,
  dark_red: colors.danger6,
  light_green: colors.success2,
  green: colors.success4,
  dark_green: colors.success6,
  light_yellow: colors.warning2,
  yellow: colors.warning4,
  dark_yellow: colors.warning6,
  light_blue: colors.info2,
  blue: colors.info4,
  dark_blue: colors.info6,
  light_pink: colors.accent2,
  pink: colors.accent4,
  dark_pink: colors.accent6,
  light_grey: colors.grey2,
  grey: colors.grey4,
  dark_grey: colors.grey6,
  'color-accumulator': `#ED7D31`,
  'color-heatload': `#000000`,
  'color-rated-power': `#8E6AA2`,
  'color-pps': `#FFCC00`,
  'color-el': `#017E0A`,
  'color-temp': `#8C0E03`,
  'color-lmfu': `#F7E55E`,
  'color-odc': `#F6E55F`,
  'color-24h-forecast': '#FFC31F',
  'color-48h-forecast': '#FF8000',
  'color-168h-forecast': '#FF0000',
  'color-2w-forecast': '#AA0000',
  availability_percentage: '#5AA832',
  availability_unavailability: '#A74165',
  availability_backing: '#2A7AB0',
  availability_outcome: '#71EFF4',
  'currency': currency,
}

const DEFAULT_ALIASES: UiConfigAliases = {
  start_time: Datetime.getISONow(-168),
  end_time: Datetime.getISONow(168),
  offset_start_time: 0,
  offset_end_time: 0,
  opt_job_type_id: 2,
  regular: 2,
  followup_with_deviations: 3,
  followup_without_deviations: 4,
  measvalues_calculations: 5,
  marginal_cost_calculation: 7,
  sandbox: 8,
  elplan_fixed_followup_with_deviations: 10,
  elplan_fixed_followup_without_deviations: 11,
  real_time: 12,
  opt_time: null,
  subtype: null,
  demand_heatload: 'demands.heat.heat',
}

export default DEFAULT_ALIASES