import { apiClient } from 'api/apiClient/apiClient'
import authStore from 'store/auth/auth'
import { snapshot } from 'valtio'

const MIN_REQUEST_DELAY = 60 * 1000
const rateLimit: Record<string, number> = {}

export async function postStat(label: string, action: string): Promise<void> {
  const snap = snapshot(authStore)
  const module = window.location?.pathname?.split(`/`)?.[1]
  const now = +new Date()
  const system = snap.activeSystem?.id ?? null
  const key = `${system ?? `noSystem`}.${module}.${label}.${action}`

  if (rateLimit[key] && now - rateLimit[key] < MIN_REQUEST_DELAY) {
    return
  }
  rateLimit[key] = now

  const time_since_login = Math.round((now - snap.loginTimestamp) / 1000)
  const data = {
    module,
    label,
    action,
    time_since_login,
    user: snap.user?.id,
    system: snap.activeSystem?.id ?? null,
  }
  await apiClient(`events`, { data, method: `POST` })
}
