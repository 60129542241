import html2canvas from 'html2canvas'
import moment from 'moment'
import print from 'print-js'

export type ExportType = `PNG` | `CSV` | `PRINT`

export type ExportTimeData = {
  time: string
  [key: string]: number | string
}

export function getFileName(fileType: string): string {
  return `aurora_export_` + moment().format(`YYYY_MM_DD`) + `.` + fileType
}

export function getChosenDateFileName(fileType: string, currentDate: ISODateTime, title?: string): string {
  return title ? title + moment(currentDate).format(`YYYY_MM_DD`) + `.` + fileType : `aurora_` + moment(currentDate).format(`YYYY_MM_DD`) + `.` + fileType
}

export async function exportDOMNode(ref: React.RefObject<Node>, type: ExportType): Promise<boolean> {
  if (!ref.current) return false

  const DOMNode = ref.current as HTMLElement

  html2canvas(DOMNode, { scrollY: -window.scrollY }).then((canvas) => {
    const dataURL = canvas.toDataURL()

    if (type === `PNG`) {
      const link = document.createElement(`a`)
      link.download = getFileName(`png`)
      link.href = dataURL
      link.click()
    } else if (type === `PRINT`) {
      print({ printable: dataURL, type: `image` })
    }
  })

  return true
}
