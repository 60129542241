import moment, { Moment } from 'moment'

export default class Datetime {
  /*
  ISO 8601 (https://en.wikipedia.org/wiki/ISO_8601)
  YYYY-MM-DDTHH:mm:ss+00:00
  */

  utcTimeString: ISODateTime

  constructor(time: string | ISODateTime) {
    this.utcTimeString = Datetime.dateToISODateTime(new Date(time))
  }

  get date(): Date {
    return new Date(this.utcTimeString)
  }

  get ISOString(): ISODateTime {
    return this.utcTimeString
  }

  static toISOString(time: Date | string | ISODateTime | Moment): ISODateTime {
    if (typeof time === `string`) {
      return new Datetime(time).ISOString
    }

    if (time instanceof Date) {
      return Datetime.dateToISODateTime(time)
    }

    if (time instanceof Datetime) {
      return time.ISOString
    }

    if (time instanceof moment) {
      const isoString = time.clone().utcOffset(0).format(`YYYY-MM-DDTHH:mm:ss`) + `+00:00`
      return new Datetime(isoString).ISOString
    }

    throw Error(`Invalid time type ${typeof time}. Value = ${time}`)
  }

  static toLocalTime(
    time: ISODateTime,
    format?:
      | `longDay`
      | `longDayText`
      | `shortDay`
      | `shortDayText`
      | 'shortDayWithHour'
      | `dateTime`
      | `dayWithDate`
      | `hour`
      | `longDayTextWithoutYear`
      | `month`
      | 'longDayTextWithoutYear'
      | 'onlyDate'
      | 'year'
      | 'date'
      | 'monthYear'
      | 'longDayAndMonthTextWithoutYear'
      | 'longDayAndMonthTextWithoutYearIfCurrentYear'
      | 'shortDayTextWithMonth'
      | 'week'
  ): string {
    const t = new Date(time)
    const m = moment(t)

    if (format === `longDay`) {
      return moment(m).format(`YYYY-MM-DD HH:mm`)
    }

    if (format === `longDayText`) {
      return moment(m).format(`ddd D MMM Y HH:mm`)
    }

    if (format === `longDayTextWithoutYear`) {
      return moment(m).format(`ddd D MMM HH:mm`)
    }

    if (format === `longDayAndMonthTextWithoutYearIfCurrentYear`) {
      const currentYear = new Date().getFullYear()
      const year = m.year()
      if (currentYear === year) {
        return moment(m).format(`ddd D MMMM HH:mm`)
      }
      return moment(m).format(`ddd D MMMM HH:mm, YYYY`)
    }

    if (format === `longDayAndMonthTextWithoutYear`) {
      return moment(m).format(`ddd D MMMM HH:mm`)
    }

    if (format === `dateTime`) {
      return moment(m).format(`D MMM HH:mm`)
    }

    if (format === 'date') {
      return moment(m).format('D')
    }

    if (format === `shortDay`) {
      return moment(m).format(`ddd D`)
    }

    if (format === `shortDayWithHour`) {
      return moment(m).format(`ddd D HH:mm`)
    }

    if (format === `shortDayText`) {
      return moment(m).format(`dddd`)
    }

    if (format === `shortDayTextWithMonth`) {
      return moment(m).format(`ddd D` + '/' + 'M')
    }

    if (format === `hour`) {
      return moment(m).format(`HH:mm`)
    }

    if (format === `month`) {
      return moment(m).format(`MMM`)
    }

    if (format === 'onlyDate') {
      return moment(m).format(`YYYY-MM-DD`)
    }

    if (format === 'year') {
      return moment(m).format('YYYY')
    }

    if (format === 'dayWithDate') {
      return moment(m).format(`ddd YYYY-MM-DD HH:mm`)
    }

    if (format === 'monthYear') {
      return moment(m).format('MMMM YYYY')
    }

    if (format === 'week') {
      return moment(m).format('w')
    }

    return moment(m).format(`YYYY-MM-DD HH:mm`)
  }

  static getISONow(offsetHours = 0, floorHour = true): ISODateTime {
    const t = new Date()

    if (floorHour) {
      t.setMinutes(0)
      t.setSeconds(0)
      t.setMilliseconds(0)
    }

    if (offsetHours) {
      t.setTime(t.getTime() + offsetHours * 60 * 60 * 1000)
    }

    return Datetime.dateToISODateTime(t)
  }

  static addHours(date: ISODateTime, hours: number): ISODateTime {
    const t = new Date(date)
    t.setTime(t.getTime() + hours * 60 * 60 * 1000)
    return Datetime.dateToISODateTime(t)
  }

  static subtractHours(date: ISODateTime, hours: number): ISODateTime {
    const t = new Date(date)
    t.setTime(t.getTime() - (hours * 60 * 60 * 1000))

    return Datetime.dateToISODateTime(t)
  }

  static addWeeks(date: ISODateTime, weeks: number): ISODateTime {
    if (weeks < 1 || weeks > 52) {
      throw new Error('Weeks should be between 1 and 52')
    }
    const t = new Date(date)
    t.setTime(t.getTime() + weeks * 7 * 24 * 60 * 60 * 1000)
    return Datetime.dateToISODateTime(t)
  }

  static getDayAfterDate(date: ISODateTime): { startTime: ISODateTime, endTime: ISODateTime } {
    const nextDate = new Date(date)
    nextDate.setDate(nextDate.getDate()+1)
    return { startTime: Datetime.getStartOfDay(Datetime.toISOString(nextDate)), endTime: Datetime.getEndOfDay(Datetime.toISOString(nextDate)) }
  }

  static getWeekNumber(date: ISODateTime): number
  {
    const currentDate = new Date(date)
    const startDate = new Date(currentDate.getFullYear(), 0, 1)
    const days = Math.floor((currentDate - startDate) /
        (24 * 60 * 60 * 1000))

    return Math.ceil(days / 7)

  }

  static ISODatetimeTo59Minutes(date: ISODateTime): ISODateTime{
    const newDate = new Date(date)
    newDate.setMinutes(59, 0)

    return Datetime.toISOString(newDate)
  }

  static ISODatetimeTo00Minutes(date: ISODateTime): ISODateTime{
    const newDate = new Date(date)
    newDate.setMinutes(0o0, 0)

    return Datetime.toISOString(newDate)
  }

  static replaceHoursToISODateTime(date: ISODateTime, hours: number, minutes?: number): ISODateTime{
    const newDate = new Date(date)
    if (minutes) {
      newDate.setMinutes(minutes, 0)
    } else {
      newDate.setMinutes(0o0, 0)
    }
    newDate.setHours(hours, 0)

    return Datetime.toISOString(newDate)
  }

  static getCurrentOptimizationHour() : ISODateTime {
    const newDate = new Date()
    newDate.setMinutes(0o0, 0)
    newDate.setHours(newDate.getHours())
    return Datetime.toISOString(newDate)
  }

  static getHoursBetween(start: ISODateTime, end: ISODateTime): ISODateTime[] {
    const dummyStart = new Date(start).getTime()
    const dummyEnd = new Date(end).getTime()
    const hours: ISODateTime[] = []

    const startDate = dummyStart < dummyEnd ? dummyStart : dummyEnd
    const endDate = dummyStart < dummyEnd ? dummyEnd : dummyStart

    for (let t = startDate; t <= endDate; t += 60 * 60 * 1000) {
      hours.push(Datetime.dateToISODateTime(new Date(t)))
    }

    return hours
  }

  static addHoursToDate(hours: string): ISODateTime {
    const newDate = new Date()

    if (hours.includes(':')) {
      const hour = Number(hours.split(':')[0])
      const minutes = Number(hours.split(':')[1])

      newDate.setHours(hour)
      newDate.setMinutes(minutes)
      newDate.setSeconds(0)
      newDate.setMilliseconds(0)

    }

    return Datetime.toISOString(newDate)

  }

  static getDatesBetween(startTime: ISODateTime, endTime: ISODateTime): ISODateTime[] {
    const startDate = new Date(startTime)
    const endDate = new Date(endTime)
    const dates: ISODateTime[] = []

    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)
    startDate.setMilliseconds(0)
    endDate.setHours(0)
    endDate.setMinutes(0)
    endDate.setSeconds(0)
    endDate.setMilliseconds(0)

    const activeDate = startDate

    while (activeDate <= endDate) {
      dates.push(Datetime.toISOString(activeDate))

      activeDate.setDate(activeDate.getDate() + 1)
    }

    return dates
  }

  static getNrOfHoursBetween(start: ISODateTime, end: ISODateTime): number {
    const startDate = new Date(start).getTime()
    const endDate = new Date(end).getTime()

    // Add "+1" to hours diff, since startTime = 12:00 to endTime = 12:00 is one hour.
    return Math.abs(endDate - startDate) / 36e5 + 1
  }

  static getNrOfMinutesBetween(startTime: ISODateTime, endTime: ISODateTime): number {
    const startDate = new Date(startTime).getTime()
    const endDate = new Date(endTime).getTime()

    const diff = Math.abs(endDate - startDate)
    const minutes = Math.floor((diff / 1000) / 60)
    return minutes
  }

  static getYearToDate(): { startTime: ISODateTime; endTime: ISODateTime } {
    const startTime = Datetime.toISOString(moment().startOf(`year`))
    const endTime = Datetime.toISOString(moment().add(-1, `days`).hour(23).minute(59).second(59))

    return { startTime, endTime }
  }

  static getEndOfDay(time: ISODateTime): ISODateTime {
    const endOfDay = Datetime.toISOString(moment(time).endOf('day').minutes(0).seconds(0))

    return endOfDay
  }

  static getStartOfDay(time: ISODateTime): ISODateTime {
    const startOfDay = Datetime.toISOString(moment(time).startOf('day').minutes(0).seconds(0))

    return startOfDay
  }

  static dateToISODateTime(time: Date): ISODateTime {
    return (time.toISOString().substring(0, 19) + `+00:00`) as ISODateTime
  }

  static isBefore(atTime: ISODateTime, compareTime: ISODateTime): boolean {
    if (!atTime || !compareTime) {
      return false
    }

    return new Date(atTime) < new Date(compareTime)
  }

  static isBeforeOrEqual(atTime: ISODateTime, compareTime: ISODateTime): boolean {
    if (!atTime || !compareTime) {
      return false
    }

    return new Date(atTime) <= new Date(compareTime)
  }

  static isAfter(atTime: ISODateTime, compareTime: ISODateTime): boolean {
    if (!atTime || !compareTime) {
      return false
    }

    return new Date(atTime) > new Date(compareTime)
  }

  static isSameDate(atTime: ISODateTime, compareTime: ISODateTime): boolean {
    return new Date(atTime).setHours(0,0,0,0) == new Date(compareTime).setHours(0,0,0,0)
  }

  static isSameHour(atTime: ISODateTime, compareTime: ISODateTime): boolean {
    return new Date(atTime).setMinutes(0,0) == new Date(compareTime).setMinutes(0,0)
  }

  static isAfterOrEqual(atTime: ISODateTime, compareTime: ISODateTime): boolean {
    if (!atTime || !compareTime) {
      return false
    }

    return new Date(atTime) >= new Date(compareTime)
  }

  static compare(a: ISODateTime, b: ISODateTime): number {
    if (Datetime.isBefore(a,b)) {
      return -1
    }
    if (Datetime.isAfter(a,b)) {
      return 1
    }
    return 0
  }

  static getTomorrowDate(): { startTime: ISODateTime, endTime: ISODateTime } {
    const today = moment()
    const tomorrow = today.clone().add(1,'days')
    return {startTime: Datetime.getStartOfDay(Datetime.toISOString(tomorrow)), endTime: Datetime.getEndOfDay(Datetime.toISOString(tomorrow))}
  }

  static getYesterdayDate(): { startTime: ISODateTime, endTime: ISODateTime } {
    const today = moment()
    const yesterday = today.clone().subtract(1,'days')
    return {startTime: Datetime.getStartOfDay(Datetime.toISOString(yesterday)), endTime: Datetime.getEndOfDay(Datetime.toISOString(yesterday))}
  }


  static getTodayDate(): { startTime: ISODateTime, endTime: ISODateTime } {
    const today = moment()
    return {startTime: Datetime.getStartOfDay(Datetime.toISOString(today)), endTime: Datetime.getEndOfDay(Datetime.toISOString(today))}
  }

  static isWeekday(atTime: ISODateTime): boolean {
    return (new Set([6, 0])).has(new Date(atTime).getDay())
  }

  static getDateOneWeekAhead(): ISODateTime {
    return Datetime.toISOString(moment().add(7, 'days'))
  }

  static getStartTimeEndTimeOfMonth(
    year: number,
    month: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  ): { startTime: ISODateTime; endTime: ISODateTime } {
    const start = new Date(year, month - 1, 1)
    const end = new Date(year, month, 1)

    const startTime = Datetime.dateToISODateTime(start)
    const endDatetime = new Datetime(Datetime.dateToISODateTime(end))
    endDatetime.addHours(-1)
    const endTime = endDatetime.ISOString

    return { startTime, endTime }
  }

  static getStartTimeEndTimeOfWeek(): { startTime: ISODateTime; endTime: ISODateTime } {
    const today = new Date()
    const start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 1)
    const end = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 7)

    const startTime = Datetime.dateToISODateTime(start)
    const endDatetime = new Datetime(Datetime.dateToISODateTime(end))
    const endTime = endDatetime.ISOString
    return { startTime, endTime }
  }

  static getStartTimeEndTimeOfWeekByDate(date: ISODateTime): { startTime: ISODateTime; endTime: ISODateTime } {
    const today = new Date(date)
    const start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 1)
    const end = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 7)

    const startTime = Datetime.dateToISODateTime(start)
    const endDatetime = new Datetime(Datetime.dateToISODateTime(end))
    const endTime = endDatetime.ISOString
    return { startTime, endTime }
  }

  static getStartTimeEndTimeOfMonthByDate(
    date: ISODateTime
  ): { startTime: ISODateTime; endTime: ISODateTime } {
    const getYear =  new Date(date).getFullYear()
    const getMonth = (new Date(date).getMonth() + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

    const period = this.getStartTimeEndTimeOfMonth(getYear, getMonth)

    return period
  }

  static getMonthStartTimeEndTimeBetweenDates(
    startTime: ISODateTime,
    endTime: ISODateTime
  ): { startTime: ISODateTime; endTime: ISODateTime }[] {
    const startYear = new Date(startTime).getFullYear()
    const endYear = new Date(endTime).getFullYear()
    const startMonth = (new Date(startTime).getMonth() + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    const endMonth = (new Date(endTime).getMonth() + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    const monthStartTimeEndTimes: { startTime: ISODateTime; endTime: ISODateTime }[] = []

    const years: number[] = []
    for (let y = startYear; y <= endYear; y++) {
      years.push(y)
    }

    let currentYear = startYear
    let currentMonth = startMonth

    while (currentYear <= endYear) {
      const { startTime: currentStartTime, endTime: currentEndTime } = Datetime.getStartTimeEndTimeOfMonth(
        currentYear,
        currentMonth
      )
      monthStartTimeEndTimes.push({ startTime: currentStartTime, endTime: currentEndTime })

      if (currentYear > endYear) {
        break
      } else if (currentMonth === endMonth && currentMonth > endMonth) {
        break
      }

      currentMonth++
      if (currentMonth > 12) {
        currentMonth = 1
        currentYear++
      }
    }

    return monthStartTimeEndTimes
  }

  static getStartTimeEndTimeOfMonthsInYear(year: number): { startTime: ISODateTime; endTime: ISODateTime }[] {
    const start = new Date(year, 0, 1)
    const end = new Date(year, 11, 31)
    const startTime = Datetime.dateToISODateTime(start)
    const endTime = Datetime.dateToISODateTime(end)

    return Datetime.getMonthStartTimeEndTimeBetweenDates(startTime, endTime)
  }

  static getStartTimeEndTimeOfMonthRelativeToNow(monthsAgo = 0): { startTime: ISODateTime; endTime: ISODateTime } {
    const yearsAgo = Math.floor(monthsAgo / 12)

    const month = ((new Date().getMonth() + 1 - monthsAgo) % 12) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    const year = new Date().getFullYear() - yearsAgo

    return this.getStartTimeEndTimeOfMonth(year, month)
  }

  static sortDatetimes(datetimes: ISODateTime[]): ISODateTime[] {
    return Array.from(new Set(datetimes)).sort((a, b) => Datetime.isAfter(a, b) ? 1 : -1)
  }

  static isWeekend(date: ISODateTime): boolean {
    const day = new Date(date).getDay()
    return day === 0 || day === 6
  }

  static getDurationInHours(startTime: ISODateTime, endTime: ISODateTime): number {
    const start = new Date(startTime)
    const end = new Date(endTime)

    const diff = Math.abs(end.getTime() - start.getTime())
    const hours = Math.floor((diff / 1000) / 60 / 60)
    return hours
  }

  static getStartAndEndOfTimePeriod(inputTime: ISODateTime, timePeriodLength: number, timeUnit: 'week' | 'month' | 'year'): { startTime: ISODateTime; endTime: ISODateTime } {
    if (timeUnit === 'month') {
      const {startTime, endTime} = Datetime.getStartTimeEndTimeOfMonthByDate(inputTime)
      return {startTime, endTime}
    }
    if (timeUnit === 'week') {
      const {startTime, endTime} = Datetime.getStartTimeEndTimeOfWeekByDate(inputTime)
      return {startTime, endTime}
    }


    return {
      startTime: Datetime.toISOString(inputTime),
      endTime: Datetime.toISOString(inputTime.clone().add(1, 'day')),
    }
  }

  static convertDate(dateString: string): ISODateTime {
    const date = new Date(dateString)
    const year = date.getUTCFullYear()
    const month = date.getUTCMonth() + 1
    const day = date.getUTCDate()
    const hours = date.getUTCHours()
    const minutes = date.getUTCMinutes()
    const seconds = date.getUTCSeconds()
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+00:00`
  }

  isBefore(time: ISODateTime): boolean {
    return Datetime.isBefore(this.utcTimeString, time)
  }

  isAfter(time: ISODateTime): boolean {
    return Datetime.isAfter(this.utcTimeString, time)
  }

  addHours(hours: number): Datetime {
    const t = new Date(this.utcTimeString)
    t.setTime(t.getTime() + hours * 60 * 60 * 1000)
    this.utcTimeString = Datetime.dateToISODateTime(t)

    return this
  }

  /**
   * Get a start time that can be used to calculate the week or month.
   * Used in keeping the state between switching between week and month view.
   *
   * If startTime is 2021-01-01 and endTime is 2021-01-31, then startTime is returned.
   * The start time can can then be used to get the first week of the month.
   *
   * If startTime is 2021-01-27 and endTime is 2021-02-02, then endTime is returned.
   * Then the end time can be used to get the month of February.
   * @param startTime The start time
   * @param endTime The end time
   * @returns The start time to use to calculate the week or month
   */
  static getStartTimeForLatestMonth(startTime: ISODateTime, endTime: ISODateTime): ISODateTime {
    const startDay = moment(startTime).date()
    const endDay = moment(endTime).date()

    return startDay < endDay ? startTime : endTime
  }
}
