import { reFetchAllDatasets } from 'api/dataset/dataset.api'
import authStore from 'store/auth/auth'
import { chartStore } from 'store/chart/chart'
import elplanStore from 'store/elplan/elplan'
import filterStore, { debugFilterStore } from 'store/filter/filter'
import { editingUiConfigStore } from 'store/uiConfig/uiConfig'
import zoomStore, { setZoom } from 'store/zoom/zoom'
import { snapshot } from 'valtio'

import { getRoute } from 'helpers/route.helper/route.helper'
import digitalTwinVersionControlStore from 'views/VersionControlView/store/DigitalTwinVersionControlStore'

window.dev.getUiConfigStore = () => snapshot(editingUiConfigStore)
window.dev.getAuthStore = () => snapshot(authStore)
window.dev.getZoomStore = () => snapshot(zoomStore)
window.dev.getElplanStore = () => snapshot(elplanStore)
window.dev.getDigitalTwinVersionControlStore = () => snapshot(digitalTwinVersionControlStore)
window.dev.reFetchAllDatasets = reFetchAllDatasets
window.dev.setZoom = setZoom
window.dev.getChartStore = () => snapshot(chartStore)
window.dev.getFilterStore = () => snapshot(filterStore)
window.dev.getDebugFilterStore = () => snapshot(debugFilterStore)
window.dev.setGrouping = (str: string) => {
  const route = getRoute()
  filterStore.groupingPerRoute[route] = str
}
