import React, { ReactElement } from 'react'

import { Hidden, IconButton, Tooltip, Icon as MuiIcon } from '@mui/material'

import auroraLogo from '/img/abs_logo_white.png'

import { useTranslation } from 'react-i18next'
import authStore from 'store/auth/auth'
import Icon from 'ui/atoms/Icon/Icon'
import WrikeFormHandler from 'ui/components/WrikeForm/wrikeFormHandler'
import { useSnapshot } from 'valtio'

import styles from './Topbar.module.less'
import ProfileMenu from './components/ProfileMenu/ProfileMenu'
import SystemMenu from './components/SystemMenu/SystemMenu'

type TopbarProps = {
  drawerOpen: boolean
  setDrawerOpen: (state: boolean) => void
  notificationCenterOpen: boolean
  setNotificationCenterOpen: (state: boolean) => void
}

export default function Topbar({ drawerOpen, setDrawerOpen, notificationCenterOpen, setNotificationCenterOpen }: TopbarProps): ReactElement {
  const { t } = useTranslation()
  const authSnap = useSnapshot(authStore)

  return (
    <header className={styles.Toolbar}>
      <Tooltip title={drawerOpen ? t(`Minimize menu`) : t(`Expand menu`)} placement="right" arrow>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={() => setDrawerOpen(!drawerOpen)}
          className={styles.Toolbar_MenuButton}
        >
          <MuiIcon className="fal fa-bars" />
        </IconButton>
      </Tooltip>

      <div className={styles.Toolbar_Image}>
        <Hidden xsDown>
          <img src={auroraLogo} alt="Aurora by Sigholm" className={styles.Toolbar_Image_Aurora} />
        </Hidden>

        {authSnap.isBeta && <div className={styles.Toolbar_Image_Beta}>Beta</div>}
      </div>
      <WrikeFormHandler
        iFrameSource={
          'https://app-eu.wrike.com/form/eyJhY2NvdW50SWQiOjEwODY5OTgsInRhc2tGb3JtSWQiOjYzODg3MH0JNDg3Njc4NTExMTI0NQlmOWU0ZTZiYzJmNWMzY2YxMDQyNjExMGM1YzkzYzhjYjc0ZTJiNDQxNTg1OTg1NTFlOGE1YWZjZWMzYjk4YTA4'
        }
        title={t(`Contact us`)}
      />
      <SystemMenu />
      <ProfileMenu />
      <IconButton onClick={() => setNotificationCenterOpen(!notificationCenterOpen)} >
        <Icon color={notificationCenterOpen ? 'white' : 'white'} size='large' icon={notificationCenterOpen ? 'fal fa-tasks-alt' : 'fad fa-tasks-alt'} />
      </IconButton>
    </header>
  )
}
