import Datetime from 'utils/datetime/datetime'
import { proxy, snapshot } from 'valtio'
import { subscribeKey } from 'valtio/utils'

type OptimizeViewStore = {
  showOutcomeCheckbox: boolean
  zoomResolutionSwitch: `day` | `week`
}

function initializeOptimizeViewStore(): OptimizeViewStore {
  const showOutcomeCheckbox = localStorage.getItem(`optimizeShowOutcomeCheckbox`) !== `false`
  const zoomResolutionSwitch = (localStorage.getItem(`optimizeZoomResolutionSwitch`) as `day` | `week`) ?? `week`
  return { showOutcomeCheckbox, zoomResolutionSwitch }
}

const optimizeViewStore = proxy<OptimizeViewStore>(initializeOptimizeViewStore())

subscribeKey(optimizeViewStore, `showOutcomeCheckbox`, (showOutcome) => {
  localStorage.setItem(`optimizeShowOutcomeCheckbox`, showOutcome ? `true` : `false`)
})

subscribeKey(optimizeViewStore, `zoomResolutionSwitch`, (zoomResolution) => {
  localStorage.setItem(`optimizeZoomResolutionSwitch`, zoomResolution)
})

export function optimizeViewRange(includeOutcome?: boolean, zoomResolution?: 'day' | 'week'): [ISODateTime, ISODateTime] {
  if (includeOutcome === undefined || zoomResolution === undefined) {
    const snap = snapshot(optimizeViewStore)
    includeOutcome = snap.showOutcomeCheckbox
    zoomResolution = snap.zoomResolutionSwitch
  }

  if (zoomResolution === `day` && !includeOutcome) {
    return [Datetime.getISONow(0), Datetime.getISONow(24)]
  }
  if (zoomResolution === `day` && includeOutcome) {
    return [Datetime.getISONow(-23), Datetime.getISONow(24)]
  }
  if (zoomResolution === `week` && !includeOutcome) {
    return [Datetime.getISONow(0), Datetime.getISONow(169)]
  }
  if (zoomResolution === `week` && includeOutcome) {
    return [Datetime.getISONow(-167), Datetime.getISONow(169)]
  }

  return [Datetime.getISONow(1), Datetime.getISONow(169)]
}

export default optimizeViewStore
