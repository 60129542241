import { createClient } from '@sigholm/dbs-js-client'
import type { DataPoint as DbSDataPoint, Dataset as DbSDataset } from '@sigholm/dbs-js-client/lib/esm/index.d'
import { apiClient } from 'api/apiClient/apiClient'
import authStore from 'store/auth/auth'
import bugsnag from 'utils/bugsnag/bugsnag'
import Datetime from 'utils/datetime/datetime'
import { snapshot } from 'valtio'


function getDbSStartAndEndTimeFromDatasetInstruction(datasetInstruction: DatasetInstruction): {
  startTime: string
  endTime: string
} {
  const { filter } = datasetInstruction
  let startTime = filter.start_time as string
  let endTime = filter.end_time as string

  // Use offset if time is not present
  if (!startTime) {
    startTime = `PT${filter.offset_start_time}H`
  }
  if (!endTime) {
    endTime = `PT${filter.offset_end_time}H`
  }

  return { startTime, endTime }
}

function getDbSAggregateFunction(datasetInstruction: DatasetInstruction): string | undefined {
  if (!datasetInstruction.filter?.aggregate) {
    return undefined
  }

  // Do not aggregate in backend
  if (datasetInstruction.filter.aggregate_in_frontend) {
    return undefined
  }

  const aggregate = datasetInstruction.filter.aggregate
  const theSameAggregate = new Set(['latest', 'min', 'max', 'sum'])
  if (theSameAggregate.has(aggregate)) {
    return aggregate
  }

  if (aggregate === 'mean') {
    return 'avg'
  }

  throw new Error(`Inknown aggregation function: ${datasetInstruction.filter.aggregate} in dataset instruction: ${datasetInstruction}`)
}

let revalidateApiTokenStatus: '' | 'loading' | 'success' | 'error' = ''
let refreshedApiTokenAt: Date | null = null
let ongoingRequest: Promise<{ dbsApiToken: string, dbsGlobalReadApiToken: string }> | null = null
const maxRefreshRate = 1000 * 60 * 5 // 5 minutes

export async function revalidateApiToken(): Promise<{ dbsApiToken: string, dbsGlobalReadApiToken: string }> {
  if (revalidateApiTokenStatus === 'loading' && ongoingRequest) {
    return ongoingRequest
  }

  if (refreshedApiTokenAt) {
    const now = new Date()
    const diff = now.getTime() - refreshedApiTokenAt.getTime()
    if (diff < maxRefreshRate) {
      throw new Error(`DbS API Token was refreshed ${diff / 1000} seconds ago, skipping revalidation...`)
    }
  }

  revalidateApiTokenStatus = 'loading'
  ongoingRequest = (async () => {
    try {
      const newToken = await apiClient<{ dbs_api_token: string, dbs_global_read_api_token: string }>('users/refresh_dbs_api_token', {
        method: 'POST',
      })
      const dbsApiToken = newToken.dbs_api_token
      const dbsGlobalReadApiToken = newToken.dbs_global_read_api_token

      if (!authStore.user) {
        throw new Error(`No user found to set DbS API Token to.`)
      }

      authStore.user.dbs_api_token = dbsApiToken
      authStore.user.dbs_global_read_api_token = dbsGlobalReadApiToken
      refreshedApiTokenAt = new Date()
      revalidateApiTokenStatus = 'success'

      return { dbsApiToken, dbsGlobalReadApiToken }
    } catch (error: any) {
      bugsnag.notify(error)
      revalidateApiTokenStatus = 'error'
      throw error
    } finally {
      ongoingRequest = null
    }
  })()

  return ongoingRequest
}

export async function initDbSApiToken(user: User): Promise<void> {
  let dbsApiToken: string | undefined = user.dbs_api_token
  let dbsBaseUrl: string | undefined = user.dbs_url

  if (!dbsApiToken) {
    const authSnap = snapshot(authStore)
    dbsApiToken = authSnap.user?.dbs_api_token
    dbsBaseUrl = authSnap.user?.dbs_url
  }

  if (!dbsApiToken) {
    const data = await revalidateApiToken()
    dbsApiToken = data.dbsApiToken
  }

  const client = createClient({
    baseUrl: dbsBaseUrl,
    apiToken: dbsApiToken,
  })

  try {
    const isOk = await client.ping()
    if (isOk) {
      return
    }
  } catch (error: any) {
    // Probably expired token
    console.warn(error)
  }

  try {
    const data = await revalidateApiToken()
    dbsApiToken = data.dbsApiToken

    const clientAfterRefresh = createClient({
      baseUrl: dbsBaseUrl,
      apiToken: dbsApiToken,
    })
    const isOkAfterRefresh = await clientAfterRefresh.ping()
    if (!isOkAfterRefresh) {
      throw new Error(`DbS API Token is not working after refresh.`)
    }
  } catch (error: any) {
    bugsnag.notify(error)
  }
}

export async function fetchDatasetsFromDbS(datasetInstructions: DatasetInstruction[]): Promise<Dataset[]> {
  const finalDatasets: Dataset[] = []
  const datasetInternalIdToDatasetIndexMap: Record<string, number> = {}
  const authSnap = snapshot(authStore)
  let dbsApiToken = authSnap.user?.dbs_api_token
  let dbsGlobalReadApiToken = authSnap.user?.dbs_global_read_api_token

  if (!authSnap.user) {
    throw new Error(`No user found to get DbS API Token from.`)
  }

  if (!dbsApiToken || !dbsGlobalReadApiToken) {
    const data = await revalidateApiToken()

    dbsApiToken = data.dbsApiToken
    dbsGlobalReadApiToken = data.dbsGlobalReadApiToken
  }

  const client = createClient({
    baseUrl: authSnap.user?.dbs_url,
    apiToken: dbsApiToken,
    onUnauthorized: async () => {
      await revalidateApiToken()
    },
  })

  const globalReadClient = createClient({
    baseUrl: authSnap.user?.dbs_global_read_url,
    apiToken: dbsGlobalReadApiToken,
    onUnauthorized: async () => {
      await revalidateApiToken()
    },
  })

  const dbsInstructions = datasetInstructions.filter((instruction) => instruction.type === 'dbs')

  await Promise.all(dbsInstructions.map(async (instruction) => {
    const contract = instruction.contract as DatasetContractDbS
    const aggregateFunction = getDbSAggregateFunction(instruction)
    const { startTime, endTime } = getDbSStartAndEndTimeFromDatasetInstruction(instruction)

    let datasets: DbSDataset[] = []
    let dataPoints: DbSDataPoint[] = []

    const clientToUse = contract.global_read_api ? globalReadClient : client

    if (aggregateFunction === 'latest') {
      const data = await clientToUse.getLatestDatasetsAndDataPoints({
        metadataFilter: contract.metadata_filter,
      })

      datasets = data.datasets
      dataPoints = data.dataPoints
    } else {
      const data = await clientToUse.getDatasetsAndDataPoints({
        startTime,
        endTime,
        metadataFilter: contract.metadata_filter,
        aggregateFunction,
      })

      datasets = data.datasets
      dataPoints = data.dataPoints
    }

    // Convert DbS IDs to AbS return_id
    const datasetIdToInternalIdMap: Record<string, string> = {}
    datasets.forEach((dataset: DbSDataset) => {
      let internalId = dataset?.metadata?.internal_id || dataset.id

      if (contract.id_renaming?.[internalId]) {
        internalId = contract.id_renaming[internalId]
      }

      datasetIdToInternalIdMap[dataset.id] = internalId
    })

    // Convert data points to AbS format
    dataPoints.forEach((dataPoint: DbSDataPoint) => {
      const internalId = datasetIdToInternalIdMap[dataPoint.datasetId]
      if (datasetInternalIdToDatasetIndexMap[internalId] === undefined) {
        datasetInternalIdToDatasetIndexMap[internalId] = finalDatasets.length
        finalDatasets.push({
          return_id: internalId,
          times: [],
          values: [],
        })
      }

      const index = datasetInternalIdToDatasetIndexMap[internalId]
      const time = Datetime.toISOString(dataPoint.time)

      finalDatasets[index].times.push(time)
      finalDatasets[index].values.push(dataPoint.value)
    })
  }))

  return finalDatasets
}