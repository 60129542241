import React, { ReactElement } from 'react'

import classNames from 'classnames'

import styles from './LoadingPlaceholderContainer.module.less'

type LoadingPlaceholderContainerProps = {
  height?: number | string
}

export default function LoadingPlaceholderContainer({ height: _height }: LoadingPlaceholderContainerProps): ReactElement {
  const minHeight = _height ? _height : '100px'
  const height = _height ?? '100%'

  return (
    <div className={classNames([styles.LoadingPlaceholderContainer, styles.LoadingPlaceholderContainer__animate])} style={{ height, minHeight }} />
  )
}
